import TeamMemberCard from "components/team/TeamMemberCard";
import { FC, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { APP_TITLE } from "config/Config";
import useScrollToTop from "components/common/useScrollToTop";
import useCounter from "components/common/useCounter";
import { Route, Routes } from "react-router-dom";
import TeamMemberPreview from "components/team/TeamMemberPreview";
import useScreenSize from "components/common/useScreenSize";
import { Header } from "components";
import Shape from "components/common/Shape";
import useTeamMembers from "components/useTeamMembers";

const TeamContainer = styled.section`
  max-width: 1740px;
  position: relative;
  margin: 0 auto;
  padding: 175px 70px 90px 70px;
  background-color: ${({ theme }) => theme.colors.layoutNeutral};
  display: flex;
  flex-direction: column;

  .content-wrapper {
    position: relative;
    z-index: 1;
  }

  .title {
    width: 1100px;
    max-width: 100%;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: calc(40rem / 16);
    font-weight: 400;
    line-height: 1.34;
    margin-bottom: 78px;
  }

  .team-members-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    margin-bottom: 100px;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    padding: 115px 25px 75px 25px;
    .title {
      font-size: calc(30rem / 16);
      margin-bottom: 40px;
    }

    .team-members-container {
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      margin: 0;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
    .team-members-container {
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
  }
`;

const Team: FC = () => {
  useScrollToTop();
  const { items } = useTeamMembers();
  const animationIndex = useCounter(items.length);
  const { width } = useScreenSize();

  const getBorderRight = useCallback(
    (index) => {
      if (width < 690) return false;
      if (width < 1100) return (index + 1) % 2 !== 0;
      if (width < 1420) return (index + 1) % 3 !== 0;
      if (width < 1740) return (index + 1) % 4 !== 0;
      return (index + 1) % 5 !== 0 && index !== items.length - 1;
    },
    [width, items]
  );

  const getBorderBottom = useCallback(
    (index) => {
      if (width < 690) return index < items.length - 1;
      if (width < 1100) return index < items.length - 2;
      if (width < 1420) return index < items.length - 3;
      if (width < 1740) return index < items.length - 4;
      return index < items.length - 2;
    },
    [width, items]
  );

  const parsedTeamMembersData = useMemo(() => {
    return items.map((member, index) => {
      return {
        ...member,
        borderRight: getBorderRight(index),
        borderBottom: getBorderBottom(index),
      };
    });
  }, [items, getBorderRight, getBorderBottom]);

  return (
    <TeamContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} — The 776 Team is nontraditional. We're builders from diverse backgrounds.</title>
      </Helmet>

      <Shape />

      <div className="content-wrapper">
        <Header showShape />

        <h2 className="title">
          The 776 Team is nontraditional.
          <br />
          We're builders from diverse backgrounds.
        </h2>
        <div className="team-members-container">
          {parsedTeamMembersData.map((member, index) => (
            <TeamMemberCard
              key={member.id}
              animate={animationIndex > index}
              member={member}
              borderRight={member.borderRight}
              borderBottom={member.borderBottom}
            />
          ))}
        </div>

        <Routes>
          <Route path=":id" element={<TeamMemberPreview items={items} />} />
        </Routes>
      </div>
    </TeamContainer>
  );
};

export default Team;
