import thisIsTheStartingLine from "./this-is-the-starting-line";
import announcingOur2021OperatorsInResidence from "./announcing-our-2021-operators-in-residence";
import theOperatorInResidenceApplicationProcessAndLearning from "./the-operator-in-residence-application-process-learnings";
import theSevenSevenSix2GrowthCaregivingCommitment from "./the-seven-seven-six-2-growth-caregiving-commitment";
import outOfTheBlocks from "./out-of-the-blocks";
import theNuanceAndTheNeedForFounderOutcomes from "./the-nuance-and-the-need-for-founder-outcomes";
import theTalentOutcomesTeamApplicationProcessAndLearnings from "./the-talent-outcomes-team-application-process-and-learnings";
import welcomeChrisVanzetta from "./welcome-chris-vanzetta";
import talentOutcomes from "./talent-outcomes";

export interface BlogPost {
  id: string;
  author: string;
  date: Date;
  title: string;
  image: string;
  imageSource?: string;
  text: JSX.Element;
}

const blogPosts = [
  talentOutcomes,
  welcomeChrisVanzetta,
  theTalentOutcomesTeamApplicationProcessAndLearnings,
  theNuanceAndTheNeedForFounderOutcomes,
  outOfTheBlocks,
  theSevenSevenSix2GrowthCaregivingCommitment,
  theOperatorInResidenceApplicationProcessAndLearning,
  announcingOur2021OperatorsInResidence,
  thisIsTheStartingLine,
] as BlogPost[];

export default blogPosts;
