import Image from "assets/images/blog/Low-Poly-portrait-woman-at-laptop_without-background-980x564.png";
import { Link } from "react-router-dom";

const theOperatorInResidenceApplicationProcessLearnings = {
  id: "the-operator-in-residence-application-process-learnings",
  author: "Katelin Holloway",
  date: new Date("04/20/2021"),
  title: "The Operator in Residence Application Process & Learnings",
  image: Image,
  text: (
    <>
      <h3>
        <p className="bold-text">
          Historically, venture capital (VC) has proven itself to be a very difficult industry to break into. Barriers
          include bias, closed networks, and even the recruiting process itself. Because we set out to build a VC firm
          differently, we knew we had to provide open and equitable access to operators specifically looking to
          transition into investor roles.
        </p>
      </h3>
      <p></p>
      <p>
        A few weeks ago, we introduced you all to our new{" "}
        <Link
          to="https://www.sevensevensix.com/news/announcing-our-2021-operators-in-residence"
          target="_blank"
          rel="noopener noreferrer"
        >
          Operators in Residence
        </Link>
        : Cristina, Hiram and Travis. We also revisited our reasons for creating this program and the goals we hope to
        achieve over the course of the year. We committed to giving you insight into how our process played out, and
        share some templates to help you adopt our approach. This is the first step. Open-sourcing our work is central
        to our firm's identity, and we invite you to help improve it.
      </p>
      <p></p>
      <p></p>
      <hr />
      <p></p>
      <p>
        💭 <strong>Our Intentions</strong>
      </p>
      <p></p>
      <p></p>
      <p></p>
      <p>
        When building from scratch, you have no choice but to start with the fundamentals: Why are you creating this,
        whom do you aim to serve, and what are your goals in bringing this solution to life? There were many different
        paths we could have taken to develop this application process. Clarifying our intentions was critical to
        implementing the process quickly. After much discussion, we established the following:
      </p>
      <ol>
        <li>
          <strong>Open. </strong>Sadly, publicly posting jobs in VC is incredibly rare. We believe that the best way to
          maximize returns is to have a team that better reflects the world in which we live, which means increasing
          opportunity and access for all. These were the most obvious reasons to have an open application process.
        </li>
        <li>
          <strong>Inclusive. </strong>Because we've been building high-performing, diverse teams for over a decade, we
          know that language matters. Job postings with cold, biased, or neutral language deter candidates looking to
          join a company with an inclusive culture.{" "}
          <Link to="https://www.sevensevensix.com/careers" target="_blank" rel="noopener noreferrer">
            This job posting
          </Link>{" "}
          was our opportunity to demonstrate to our candidates who we are and what we value, and to give them a taste of
          what it would be like to work alongside us in the year ahead. We clearly stated what the role was and what the
          role was not. We encouraged interested candidates to apply, even if they felt they didn't meet all the
          requirements.&nbsp;In the application questions, we were intentional about creating space for an applicant's{" "}
          <em>authentic voice</em>. Each question left plenty of room for creativity. The goal was for every prompt to
          feel like an invitation, because that's exactly what this program was designed to be.
        </li>
        <li>
          <strong>Useful. </strong>We understood that each applicant's time was valuable, and we aimed to be respectful
          and thoughtful in asking for a commitment from others. Recognizing that our applicants would be people who
          were at a crossroads in their careers, we genuinely wanted applicants to learn something about themselves and
          find clarity in their own journeys. Even a job application could be a two-way process, with both parties
          learning something new. Anything we asked of an applicant was meant to be something they could use as an
          exercise in self-reflection or in building their personal brand. Every task or question needed to serve as an
          opening for personal growth.
        </li>
        <li>
          <strong>Cloaked. </strong>We're well aware of the many biases entrenched in traditional recruiting processes.
          This is true in many industries, but even more so in the land of venture capital, with many top-tier firms
          relying on pedigrees affiliated with only the top five ivy league schools. We know from experience that
          pedigree requirements filter out people with incredible talent and aptitude. We don't care what college
          candidates—or founders or anyone else we work with— attended-or if they attended at all. It was important to
          us to reach beyond our existing networks for these roles, so we committed to a cloaked evaluation for the
          first stage. Practically, however, we were just a three-person team, and hosting an entirely opaque
          application process during a global pandemic would have been nearly impossible.
          <ul>
            <li>
              What we came up with was a “cloaked(ish)” process, meaning we chose not to ask for resumes as a part of
              the application process. We believe that resumes are outdated and only tell one part of the story
              (frankly, the least important bits). We let candidates own their narrative and tell us who they are in
              their own words, rather than presenting a series of stacked logos on a timeline.
            </li>
            <li>
              We also needed to hire an external project manager to ensure we had a system set up on the backend to
              collect responses and pull out unique identifiers before our first round of assessments. Once that was
              established, we collated responses and assessed them in cloaked tranches, only revealing their names AFTER
              we had selected whom we wanted to move forward to the next stage. Having an external third party also
              allowed us to remove ourselves from the assessment process when a candidate was known by a Seven Seven Six
              team member.
            </li>
          </ul>
        </li>
      </ol>
      <p></p>
      <p>
        From these commitments, we built an imperfect but revealing application and evaluation process that yielded an
        incredibly diverse applicant pool unlike anything typically experienced by a venture capital firm—or even by
        most tech companies. We had 1,100 applicants, with 83% self-identifying as underrepresented in venture. This
        tells us a few things: (1) the talent pipeline has never been the problem in VC, (2) standard recruiting
        processes prevent great candidates from entering this industry, and (3) if we really care about inclusion, we
        need to share our process and invite others to help make it better.
      </p>
      <hr />
      <p></p>
      <p>
        🕵️ <strong>The Recruiting Process</strong>
      </p>
      <p></p>
      <p>
        Our recruiting process consisted of three distinct rounds: the initial application, the project, and the
        interview. Throughout the three stages, we managed a comprehensive evaluation process designed to reduce bias,
        reset anchor points, and surface top talent.
      </p>
      <p></p>
      <p>
        <strong>
          <em>Round 1: The Application</em>
        </strong>
      </p>
      <p></p>
      <p>
        The initial application was designed to truly showcase our candidates—and, in the process, eliminate the need
        for resumes. Before drafting our short essay questions, we considered our goals and assessment criteria. We
        identified the two primary buckets that we believed would enable us to best assess the candidates'
        qualifications: <strong>Identity &amp; Skillset</strong> and <strong>Perspective</strong>. Within those two
        buckets, we outlined the information we hoped to gain in the process. Last, we considered the data collection
        needed to help us understand if we were attracting diverse candidates from a variety of backgrounds.
      </p>
      <ol>
        <li>
          <strong>Identity &amp; Skillset</strong>
          <ol>
            <li>Tell us who you are without using your resume.</li>
            <li>Show us who you are as an operator.</li>
          </ol>
        </li>
        <li>
          <strong>Perspective</strong>
          <ol>
            <li>How does your unique perspective shape the way you view the world?</li>
            <li>How do you perceive yourself?</li>
          </ol>
        </li>
        <li>
          <strong>Data Collection</strong>
          <ol>
            <li>Did we actually attract a diverse talent pool?</li>
            <li>What types of operators were most interested in our program?</li>
          </ol>
        </li>
      </ol>
      <p>
        After we created our strawman, we drafted application questions. We could have used the questions from the
        framework above, but we know that in order to build an inclusive application process,{" "}
        <em>language truly matters</em>. How you ask a question is just as important as how a candidate responds. Your
        job posting and your application questions should be crafted with intention and care. Our language needed to
        reflect our firm's culture. Here's what we came up with:
      </p>
      <p>
        <strong>The Questions</strong>
      </p>
      <p>
        <strong>Identity Question:</strong>{" "}
        <em>
          What would you like us to know about your identity? This is your opportunity to proactively share anything
          about yourself that has helped to shape your perspective. We want to know anything that you feel has helped to
          develop who you are in this world of work. Tell us the story of you!
        </em>
      </p>
      <p>
        <strong>Why did we choose this question?&nbsp;</strong>
      </p>
      <ul>
        <li>
          We invited our applicants to share whatever they felt was important for us to know about who they are in this
          world. We felt this was far more compelling than surfacing a series of tick-boxes with demographic
          questions.&nbsp;
        </li>
      </ul>
      <p></p>
      <p>
        <strong>What were we looking for?</strong>
      </p>
      <p></p>
      <ul>
        <li>
          We honestly didn't know how people would respond to this prompt. Our hope was that they would share what was
          most important to them, but we had little expectation of what that might entail. This was our most
          experimental question.
        </li>
      </ul>
      <p></p>
      <p>
        <strong>What did we learn?</strong>
      </p>
      <ul>
        <li>
          Identity is deeply personal. Some people chose to share their professional backgrounds, while others focused
          on their demographics. For many, this essay included what their childhood was like, who their role models are,
          and why they made different choices in their careers. This question was the most impactful, in an extremely
          unexpected way. We felt as if we really got to know these people. It allowed us to enter into our first
          conversations with a profoundly deep sense of connection. We're convinced that these conversations never would
          have happened in a traditional recruiting process, and we plan on using this question in every future
          application.
        </li>
        <li>
          People who went light in detail on this question rarely made it through to the second round. We discovered
          that vulnerability and openness were critical traits of the people we were seeking to add to the team.
        </li>
        <li>
          We had applicants with incredibly unique backstories: race car drivers, microbiologists, pro athletes,
          elementary school teachers, dentists, fighter pilots, founders, journalists, and seasoned venture capitalists
          at top-tier firms. We saw a ton of parents, self-described “army brats,” and “third-culture kids.” We read the
          words <em>“I was the first in my family to x”</em> and<em> “as the only person in my family to y”</em> in
          application after application. Candidates came to us from all over the US, including Cincinnati, Detroit, St.
          Louis, Miami, and rural Kansas. The best part? Nearly all of them were qualified, and all of them, no doubt,
          will make great investors one day.
        </li>
        <li>
          We are certain that some great candidates may have been screened out at this step because they chose to
          respond with a more traditional answer, but we knew we were looking for those who spotted the invitation to go
          beyond just listing resume accomplishments.&nbsp;
        </li>
      </ul>
      <p></p>
      <strong>Skillset Question:</strong>{" "}
      <em>
        What is something that you have built or created to help solve a problem or an inefficiency within your own
        organization or for your industry at large? Share anything about your creative process that you would like in
        order to give us insight into how you build things. If you believe it's additive, feel free to include links
        that direct us to your work or demonstrate the impact of your solution. (Please do not create anything
        specifically for this application.)&nbsp;
      </em>
      <p></p>
      <strong>Why did we choose this question?&nbsp;</strong>
      <p></p>
      <ul>
        <li>
          You've heard us say this over and over again: we fundamentally believe that the best operators make the best
          investors. World-class operators know how to identify problems or inefficiencies and can quickly
          operationalize solutions that serve the entire organization. This is precisely the type of support founders
          want from their investors. As an Operator in Residence (and as an investor), a candidate's experience in
          architecting solutions is invaluable in supporting the Seven Seven Six founders. We wanted to learn more about
          how applicants became operators who stepped in when their organizations needed creative solutions.
        </li>
      </ul>
      <p></p>
      <p>
        <strong>What were we looking for?</strong>
      </p>
      <p></p>
      <ul>
        <li>
          We wanted to see their work in action! This question helped us to determine the candidates' ability to
          translate their work into a format that helped others. Builders gonna build, and we were eager to hear their
          passion and commitment to their craft.&nbsp;
        </li>
      </ul>
      <p></p>
      <p>
        <strong>What did we learn?</strong>
      </p>
      <p></p>
      <ul>
        <li>
          Skills are ridiculously transferable. It was fascinating to learn about solutions or programs that people had
          designed for a tech company that were rooted in a learning or experience from a completely different industry.
          How did working at an oyster farm influence a candidate's construction of a metrics dashboard? How did a
          former kindergarten teacher wind up building a community support feature that gamified patience? The responses
          were stunningly articulate and demonstrated the agility of gifted operators.&nbsp;
        </li>
      </ul>
      <p></p>
      <p>
        <strong>Perspective Question:</strong>{" "}
        <em>
          We believe that unique perspectives help us to make better investments and support our portfolio companies in
          unparalleled ways. Many industries can benefit from this, but we're curious to learn how your own unique
          perspective will improve the VC landscape.
        </em>
      </p>
      <p></p>
      <p>
        <strong>Why did we choose this question?&nbsp;</strong>
      </p>
      <p></p>
      <ul>
        <li>
          To be a successful investor, you need to have a perspective that is unique to you. We wanted to learn more
          about how these candidates were translating their lived experiences into their investing perspectives.
        </li>
      </ul>
      <p></p>
      <p>
        <strong>What were we looking for?</strong>
      </p>
      <p></p>
      <ul>
        <li>
          We had zero expectation for applicants to show up with a robust and fully articulated investor thesis. We
          wanted to hear the raw motivations behind their desire to move into the VC industry. We were hoping to read
          something that made us curious to learn more.&nbsp;
        </li>
      </ul>
      <p></p>
      <p>
        <strong>What did we learn?</strong>
      </p>
      <p></p>
      <ul>
        <li>
          The drivers for this cohort of candidates were awesomely unique. Because we were reviewing questions in
          cloaked tranches, it was really interesting to read about the perspectives outside of the context of the other
          responses. Was this the kindergarten teacher or the head of production at a unicorn tech company? We honestly
          had no idea, and that made reading these short essays really, really fun.
        </li>
        <li>
          We also learned that there are critical perspectives that are starkly absent from the industry at large. We
          heard voices in these responses that will help the next generation of innovators and entrepreneurs achieve
          their potential in ways that the current majority of investors simply cannot.
        </li>
      </ul>
      <p></p>
      <p>
        <strong>Self-Perception Question:</strong>{" "}
        <em>
          Coaching is a large part of the work that we do to support our portfolio founders. If you were your own career
          coach at this moment, what advice would you give yourself? Describe where you are in your journey, and what's
          led you to apply to Seven Seven Six.
        </em>
      </p>
      <p></p>
      <p>
        <strong>Why did we choose this question?&nbsp;</strong>
      </p>
      <p></p>
      <ul>
        <li>
          This question was created to help us have insight into our candidates' self-awareness and commitment to
          creating change in their lives.&nbsp;
        </li>
        <li>
          We were also hoping that this prompt would help us understand how someone might coach our portfolio companies.
          Good answers did not need to explicitly discuss this dynamic, but they did need to give us a clear picture of
          how they might show up in a coaching role.
        </li>
      </ul>
      <p></p>
      <p>
        <strong>What were we looking for?</strong>
      </p>
      <ul>
        <li>
          Vulnerability. Anyone can write a few short sentences in the third person and give themselves a pep talk. What
          we really wanted to hear was what made this particular fork in the road the one that would change their life
          forever—regardless of Seven Seven Six, this opportunity, or even their ability to get a job in VC. What was
          holding them back? What was propelling them forward?&nbsp;
        </li>
      </ul>
      <p></p>
      <p>
        <strong>What did we learn?</strong>
      </p>
      <p></p>
      <ul>
        <li>
          The people who really, truly leaned into this question were surprisingly open, honest, and reflective. It was
          obvious that some were writing this piece for themselves, almost as if they were hearing their own words of
          urgency for the first time. We felt humbled and honored to be reading this alongside them, and we found
          ourselves rooting for them in ways we didn't expect. This was the last essay we read from candidates, and it
          left us feeling very optimistic about the future of our industry.&nbsp;
        </li>
      </ul>
      <p></p>
      <p>
        <strong>Demographic Questions:</strong>&nbsp;
      </p>
      <p></p>
      <p>
        In addition to the four essay questions, we also asked two questions related to demographics: (1){" "}
        <em>Do you consider yourself to be underrepresented in venture capital? </em>and (2){" "}
        <em>What would you consider to be your primary function(s) as an operator?</em>
      </p>
      <p></p>
      <p>
        <strong>Why did we choose these questions?&nbsp;</strong>
      </p>
      <ul>
        <li>
          The operator question would help us out later in the process when we were balancing the skill sets on the team
          to best support our portfolio companies.
        </li>
        <li>
          As for the “underrepresented in venture” question, we needed to know if our job posting and sourcing strategy
          was effective in reaching diverse applicants.&nbsp;
        </li>
      </ul>
      <p>
        <strong>What were we looking for?</strong>
      </p>
      <ul>
        <li>
          For both questions, the only thing we hoped to gain was data. These questions were not a consideration or
          factor in the first or second round of assessments and remained hidden for the reading of the four short essay
          questions. Only after the project round did we take the “area of functional expertise’ data into the selection
          process, ensuring we had a well-rounded team.
        </li>
      </ul>
      <p></p>
      <p>
        <strong>What did we learn?</strong>
      </p>
      <ul>
        <li>
          Cross-functional misfits were the norm! While we made the primary function question a multi-select, we
          expected candidates to select one or <em>maybe</em> two boxes. Applicants felt that they had many areas of
          expertise and were comfortable identifying themselves as operators who moved fluidly across
          organizations.&nbsp;
        </li>
        <li>
          Another interesting finding was that most applicants selected the “People &amp; Culture” box, feeling
          connected enough to the work to identify as an operator with this area of expertise. However, true People
          &amp; Culture specialists (despite the fact that they are probably some of the few operators who truly work
          across every part of the organization) were the only cohort who earnestly selected just one box.&nbsp;
        </li>
        <li>
          83% of applicants viewed themselves as being underrepresented in venture capital. At first, we were proud of
          ourselves for reaching diverse pools of candidates. Upon further reflection, we realized that this demographic
          data simply reflects the state of the industry itself.&nbsp;
        </li>
      </ul>
      <p></p>
      <p>
        <strong>
          <em>Round 2: The Project</em>
        </strong>
      </p>
      <p>
        After our review of the 1,100 applications, we invited 113 people to participate in the next step. We challenged
        applicants to provide an example of what it would look like if they productized or operationalized their own
        experience and wisdom to serve a startup. We let applicants lean into their own strengths and provided a fairly
        open prompt that yielded a wide variety of results, from slide decks and dashboards to movie trailers.&nbsp;
      </p>
      <strong>Why did we choose this prompt?&nbsp;</strong>
      <ul>
        <li>
          Seeing operators clearly and articulately bring their work to life was very important to us. We didn't want to
          create more than an hour's worth of work for the applicants, so we encouraged them to highlight projects
          they'd worked on previously. And because we believe that potential investors can build their own reputations
          for being helpful to founders outside of having a job at a VC firm, we encouraged the operators to open-source
          their work. By sharing with the world, they would help other early-stage founders build their companies and
          would elevate their personal brands.&nbsp;
        </li>
      </ul>
      <p>
        <strong>What were we looking for?</strong>
      </p>
      <ul>
        <li>
          Outstanding communication and craftsmanship. The applicants may have stolen our hearts in the first phase of
          the application process with beautiful writing or compelling storytelling, but could they back it up with true
          expertise?
        </li>
      </ul>
      <p>
        <strong>What did we learn?</strong>
      </p>
      <ul>
        <li>
          We were awestruck to see our candidates come to life through these projects. We felt very connected to each of
          them even before this stage; seeing their faces on a Loom video or listening to their baby babble on a podcast
          with them was truly a delight! The thoughtfulness of these submissions was very much appreciated.&nbsp;
        </li>
        <li>
          Because we left the prompt for these projects so wide open, it was challenging for us to assess them. The
          variety alone was enough to cause us to have to compare notes earlier in the process than we had planned. We
          were flexible with ourselves, but next time we may consider putting more boundaries in place to better rate
          and rank submissions.&nbsp;
        </li>
        <li>
          Many people actually open-sourced their work. We weren't sure how candidates would respond to our
          encouragement to put themselves out there, and they showed up! We've heard from some candidates that although
          they didn't get the gig with us, they were able to land jobs or advisory roles because they open-sourced their
          work. Music to our ears!
        </li>
        <li>
          The best submissions had a few things in common:
          <ul>
            <li>They wove in the applicants' unique voices and perspectives.</li>
            <li>
              They stepped into the shoes of a startup founder and found the unique intersection of what that founder
              would want and what they as applicants knew well.&nbsp;
            </li>
            <li>They practiced showing and not telling in their communication.&nbsp;</li>
            <li>They were courageous in taking chances.</li>
          </ul>
        </li>
      </ul>
      <p>
        <strong>
          <em>Round 3: The Interview</em>
        </strong>
      </p>
      From this group, we narrowed down our candidate pool to 21 before moving on to interviews. The finalists had live
      interviews with some combination of the founding team members. In our interviews, we focused on learning more
      about where these people envisioned themselves developing in the next year and shared more about what we were
      building here at the firm.&nbsp;<p></p>
      While typically the “in-person” interview provides the most tangible insights into who candidates are and the
      dynamic between team members, we really felt as if we knew the people on the other side of our screens the moment
      they logged on. It was almost as if we were dialing in to reconnect and share a laugh with an old friend.<p></p>
      After the interviews concluded, we compared our notes, reviewed our founder requests, discussed the benefits to
      our portfolio for each particular area of expertise, and -at long last- made decisions on our final three.
      Thankfully, we had a 100% acceptance rate and are proud to be working alongside Travis, Hiram, and Cristina.&nbsp;
      <hr />
      <p>
        📋 <strong>The Evaluation Process</strong>
      </p>
      The evaluation of candidates during this new application process became a creative exercise in and of itself. We
      mentioned earlier that in an effort to reduce our biases, we decided to bring on an external project manager. We
      contracted{" "}
      <Link to="https://www.linkedin.com/in/samuel-lazarus-he-him-b04bba1a/" target="_blank" rel="noopener noreferrer">
        Samuel Lazarus
      </Link>{" "}
      to help us manage the logistics of creating and maintaining a framework that allowed us to view the four essay
      questions in cloaked tranches. Sam's background in creating hiring mechanisms that screened for potential over
      paper was helpful and his commitment to ensuring a fantastic candidate experience was felt through every step of
      the process.<p></p>
      <p>
        We've shared Sam's assessment tool here, designed specifically for this Operator in Residence Program hiring
        effort. Sam (and we here at Seven Seven Six) invite you to try recruiting for one role in your organization with
        this approach. Tell us what worked and what didn't; all feedback is warmly welcomed!
      </p>
      <Link
        to="https://docs.google.com/spreadsheets/d/1pcJWlg_vrZiBDYHR36ynAZlip7mkD2tCwdYIt_yK3Us/edit?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        TEMPLATE: Seven Seven Six Operator in Residence Application Assessment Tool
      </Link>
      <hr />
      <p>
        🏫 <strong>Other Lessons Learned</strong>
      </p>
      All in all, we're very happy with how things turned out. We've hired a phenomenal team and have met some truly
      gifted people who are now a part of the extended Seven Seven Six community. In the spirit of sharing openly with
      you all and, specifically, those who are aiming to use a process similar to ours, we want to quickly share some
      other lessons learned.&nbsp;<p></p>
      <strong>Where we could have done better:</strong>
      <ul>
        <li>
          Broadening our reach and amplifying our job posting in other communities. It was obvious in the applications
          that there were certain communities that really caught wind of our posting (shoutout to everyone who helped us
          amplify). It was equally as obvious that we did not reach all potential candidate pools. We aim to have a more
          pragmatic approach to outreach for next year's cohort.&nbsp;
        </li>
        <li>
          We did our best to ensure our application was accessible to all candidates, and we recognize that we could
          have spent more time on things like color contrast, adding subtitles to our video prompts, and offering
          alternative modes of applying that didn't involve submitting written essays. We intend to revisit these
          investments in the future.
        </li>
        <li>
          We're <em>still</em> working on getting personalized feedback to all 113 folks who made it to the project
          stage. We overcommitted and are struggling to get those notes sent out. We intend on honoring this commitment,
          but we understand that effective feedback is given in a timely manner. (Thanks to those of you out there still
          waiting to hear from us!) We'll better assess our bandwidth next time around.&nbsp;
        </li>
      </ul>
      <br />
      We also want to call out the things that were really, really hard but were absolutely worth the effort. We were
      challenged at times and debated wavering on these things. We're so glad we didn't.&nbsp;<p></p>
      <ul>
        <li>
          <strong>We rejected referrals. </strong>We are proud of our networks, and it was incredibly challenging to
          tell people we know and respect that we would not be taking their referrals directly. We offered the website
          to apply and left it at that. Because we were running our opaque(ish) process, many referrals were screened
          out long before we even knew they applied. For applicants that didn't make it to the second round, we never
          even saw their names. (This made some people very cranky with us.)
        </li>
        <li>
          <strong>We didn't second-guess our process.</strong> Even when it was killing us and we wanted to peek at the
          backend to see who our mystery candidates were, we didn't. Even when a friend asked us where they were in the
          process, we didn't check. We didn't push anyone through that didn't meet the threshold. Our biases were
          checked daily and some healthy conversations were had across the team and our stakeholder groups.
        </li>
        <li>
          <strong>We took the time to collect real feedback on our application and job posting. </strong>Not a quick
          “hey, can you read this for me,” but <em>real</em> feedback from many people in and out of our networks. The
          feedback we received was invaluable. We'd like to thank the following people who truly helped co-create what
          we've shared here today: Mac Conwell, Kirsti Chou, Megan Ruan, Alaina Beaver, Lars Schmidt, Stacy Donovan
          Zapar, Simmone Taitt, Ben Ramirez, Kofi Frimpong, Chris Vanzetta, Jessica Yuen, James Day, Anke Huiskes,
          Melissa Moore, Tim Salau, the team at Sunshine Sachs, the team at Plural, the team at Hack Diversity—Jody
          Rose, Alexa Goldman, and Angela Liu, and, last (but certainly not least), Katelin's 94 year old grandmother
          Sally. (Hi, grandma! 👋)
        </li>
      </ul>
      <p>
        We discovered loads from this process and have plenty of inspiration and motivation to make it even better for
        our next cohort of Operators in Residence. We hope this helps you and that you'll improve upon it, sharing what
        you learn along the way. There is so much work yet to be done to create an inclusive industry and recruiting
        process. This is just the beginning…
      </p>
      <hr />
      <blockquote>
        <p>
          <strong>
            🧮<em> By the Numbers</em>
          </strong>
        </p>
        <p>
          <em>1,100 Applicants in 8 Days, 6 Questions</em>
        </p>
        <p>
          <em>1,100 → 113 (~10%) Moved on to Round 2</em>
        </p>
        <p>
          <em>113 → 21 (18.5%) Moved to Round 3</em>
        </p>
        <p>
          <em>21 → 3 (0.27%) Overall Acceptance Rate (</em>
          <Link
            to="https://www.ivycoach.com/2024-ivy-league-admissions-statistics/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <em>average acceptance rate</em>
          </Link>
          <em> of 2024 Ivy League schools is 7.2%)</em>
        </p>
      </blockquote>
    </>
  ),
};

export default theOperatorInResidenceApplicationProcessLearnings;
