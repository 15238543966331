import { FC } from "react";
import styled from "styled-components";
import StatCard from "components/common/StatCard";
import { ColSpanEnum, StatCardInterface } from "enum/stats";
import { generateColSpanClasses } from "utils/utils";

const StatsSectionDiv = styled.section<{ dark: boolean; squaredBottom: boolean }>`
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  overflow: hidden;

  .stats-wrapper {
    display: grid;
    grid-template-columns: repeat(60, 1fr);
    /* add width to cover the outside borders */
    margin-left: -2px;
    margin-top: -2px;
  }

  h3 {
    display: block;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.6;
    text-transform: uppercase;
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.layoutDark};
    margin-bottom: 50px;
  }

  .stat-card {
    grid-column: span 10;

    ${generateColSpanClasses(ColSpanEnum.XL)}

    @media ${({ theme }) => theme.mediaQueries.mediumDesktop} {
      ${generateColSpanClasses(ColSpanEnum.MEDIUM_DESKTOP)}
    }
    @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
      ${generateColSpanClasses(ColSpanEnum.BELLOW_TABLET_LANDSCAPE)}
    }
    @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
      ${generateColSpanClasses(ColSpanEnum.BELLOW_TABLE_PORTRAIT)}
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    h3 {
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 25px;
    }
  }
`;

interface StatsSectionProps {
  className?: string;
  dark?: boolean;
  squaredBottom?: boolean;
  stats: StatCardInterface[];
  backgroundColor?: string;
}

const StatsSection: FC<StatsSectionProps> = ({ dark, stats, squaredBottom, className, backgroundColor }) => {
  return (
    <StatsSectionDiv dark={dark} squaredBottom={squaredBottom} className={className}>
      <div className="stats-wrapper">
        {stats.map((stat, index) => (
          <StatCard className="stat-card" key={index} {...stat} backgroundColor={backgroundColor} />
        ))}
      </div>
    </StatsSectionDiv>
  );
};

export default StatsSection;
