import { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import clsx from "clsx";
import { ReactComponent as XIcon } from "../assets/icons/x.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/instagram.svg";
import { ReactComponent as LinkedinIcon } from "../assets/icons/linkedin.svg";
import { ReactComponent as StarSvg } from "assets/images/Star.svg";
import { Link, useLocation } from "react-router-dom";
import useSignUp from "./useSignUp";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_API_KEY } from "config/Config";
import Button from "./common/Button";

const FooterSection = styled.footer`
  max-width: 1740px;
  margin: 0 auto;
  scroll-margin-top: 100px;
  padding: 0 70px 46px 70px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 170px;

  address {
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.colors.layoutDark};
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: all 0.3s ease-in-out;
    a:hover {
      color: ${({ theme }) => theme.colors.textDisabled};
    }
    .item {
      margin-top: 40px;
    }
  }

  .main-section {
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
    gap: 50px;
    border-top: 1px solid #e0e0e0;
  }

  .contact-information {
  }

  .social-media-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }

  .form-container {
    max-width: 575px;

    h2 {
      padding-right: 40px;
      padding-bottom: 20px;

      color: ${({ theme }) => theme.colors.layoutDark};
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .rights-section {
    color: ${({ theme }) => theme.colors.layoutDark};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    opacity: 0.5;
    p {
      margin: 0px;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    padding: 0 25px 80px 25px;
    gap: 40px;

    address {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      .item {
        margin-top: 26px;
      }
    }

    .social-media-container {
      margin: 0;
    }

    .main-section {
      padding-top: 40px;
      flex-direction: column-reverse;
      gap: 40px;
    }

    .form-container {
      h2 {
        font-size: 32px;
        line-height: normal;
      }
    }

    .rights-section {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    border-top: 1px solid #e0e0e0;
    .main-section {
      border-top: none;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  .input-wrapper {
    position: relative;
    margin-bottom: 10px;

    .prefix {
      position: absolute;
      top: 0;
      left: 0;
      color: ${({ theme }) => theme.colors.textPrimary};
      opacity: 0.5;
      font-size: calc(28rem / 16);
      transition: all 0.3s ease-in-out;
    }

    &.with-prefix input {
      padding-left: 40px;
    }

    .saving-indicator {
      position: absolute;
      right: 10px;
      top: 15px;
      width: 25px;
      height: 25px;
      animation: spin 2s linear infinite;
    }

    &.error-state input {
      border-color: ${({ theme }) => theme.colors.errorColor};
    }
    &::after {
      content: " ";
      width: 100%;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      border-bottom: 2px solid transparent;
      transition: all 0.3s ease-in-out;
    }
    &:focus-within {
      .prefix {
        opacity: 0.8;
      }
      &::after {
        border-color: ${({ theme }) => theme.colors.brandColor};
      }
    }
  }

  &:focus-within {
    position: relative;
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      content: "";
      display: block;
      bottom: 1px solid ${({ theme }) => theme.colors.brandColor};
    }
  }

  input {
    width: 100%;
    background-color: inherit;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.textDisabled};
    padding: 10px 0;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: calc(28rem / 16);
    line-height: calc(40rem / 16);
    transition: all 0.3s ease-in-out;

    ::placeholder {
      color: ${({ theme }) => theme.colors.textPrimary};
      opacity: 0.5; /* Firefox */
    }

    ::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: ${({ theme }) => theme.colors.textPrimary};
      opacity: 0.5;
    }

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.brandColor};
      position: relative;
    }

    @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
      font-size: calc(22rem / 16);
      line-height: calc(30rem / 16);
    }
  }

  .error-message {
    margin: 0px;
    padding-top: 20px;
    color: ${({ theme }) => theme.colors.errorColor};
    opacity: 1;
  }
`;

const FormSubmitSection = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    flex-direction: column-reverse;
    gap: 30px;
  }
`;

const StyledXIcon = styled(XIcon)`
  width: 59px;
  height: 59px;
  transition: fill 0.3s ease-in-out;
  path {
    fill: ${({ theme }) => theme.colors.layoutDark};
  }
  circle {
    stroke: ${({ theme }) => theme.colors.layoutDark};
  }

  &:hover {
    path {
      transition: fill 0.3s ease-in-out;
      fill: ${({ theme }) => theme.colors.layoutLight};
    }
    transition: fill 0.3s ease-in-out;
    fill: ${({ theme }) => theme.colors.layoutDark};
  }
`;

const StyledInstagramIcon = styled(InstagramIcon)`
  width: 59px;
  height: 59px;
  path {
    fill: ${({ theme }) => theme.colors.layoutDark};
  }
  circle {
    stroke: ${({ theme }) => theme.colors.layoutDark};
  }

  &:hover {
    path {
      transition: fill 0.3s ease-in-out;
      fill: ${({ theme }) => theme.colors.layoutLight};
    }
    transition: fill 0.3s ease-in-out;
    fill: ${({ theme }) => theme.colors.layoutDark};
  }
`;

const StyledLinkedinIcon = styled(LinkedinIcon)`
  width: 59px;
  height: 59px;
  path {
    fill: ${({ theme }) => theme.colors.layoutDark};
  }
  circle {
    stroke: ${({ theme }) => theme.colors.layoutDark};
  }

  &:hover {
    path {
      transition: fill 0.3s ease-in-out;
      fill: ${({ theme }) => theme.colors.layoutLight};
    }
    transition: fill 0.3s ease-in-out;
    fill: ${({ theme }) => theme.colors.layoutDark};
  }
`;

const Footer: FC = () => {
  const {
    saving,
    email,
    errors,
    success,
    instagram,
    twitter,
    emailError,
    recaptchaRef,
    handleEmailChange,
    handleInstagramChange,
    handleTwitterChange,
    onSubmit,
  } = useSignUp();

  const location = useLocation();

  const emailInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (location.hash === "#sign-up") {
      // show highlight
      emailInputRef.current?.focus();
    }
  }, [location]);

  return (
    <FooterSection id="sign-up">
      <div className="main-section">
        <div className="contact-informations">
          <div className="social-media-container">
            <Link to="https://twitter.com/sevensevensix" target="_blank" rel="noopener noreferrer">
              <StyledXIcon />
            </Link>
            <Link to="https://www.instagram.com/776fund/" target="_blank" rel="noopener noreferrer">
              <StyledInstagramIcon />
            </Link>
            <Link to="https://www.linkedin.com/company/sevensevensix/" target="_blank" rel="noopener noreferrer">
              <StyledLinkedinIcon />
            </Link>
          </div>
          <address>
            <a href="mailto:contact@sevensevensix.com" className="item">
              contact@sevensevensix.com
            </a>
            <span className="item">
              Text us: <a href="tel:+1 661 776 2020">+1 661 776 2020</a>
            </span>
          </address>
        </div>
        <div className="form-container">
          <h2>What's the team at 776 thinking about this week?</h2>
          {success ? (
            <h2 style={{ paddingTop: "47px", paddingBottom: "20px" }}>Welcome to the club! :)</h2>
          ) : (
            <Form onSubmit={onSubmit}>
              <div className={clsx("input-wrapper", emailError && " error-state")}>
                <input
                  ref={emailInputRef}
                  type="email"
                  name="email"
                  aria-labelledby="Email"
                  placeholder="Your email here"
                  onChange={handleEmailChange}
                  value={email}
                />
                {saving && <StarSvg fill="#000" className="saving-indicator" />}
              </div>
              <div className="input-wrapper with-prefix">
                <span className="prefix">@</span>
                <input
                  type="text"
                  value={twitter}
                  aria-labelledby="Twitter"
                  placeholder="twitter (optional)"
                  onChange={handleTwitterChange}
                />
              </div>
              <div className="input-wrapper with-prefix">
                <span className="prefix">@</span>
                <input
                  type="text"
                  name="instagram"
                  aria-labelledby="Instagram"
                  placeholder="instagram (optional)"
                  onChange={handleInstagramChange}
                  value={instagram}
                />
              </div>
              {!!errors && <div className="error-message">{errors}</div>}
              <FormSubmitSection>
                <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={RECAPTCHA_API_KEY} />
                <Button type="submit" disabled={saving}>
                  {saving ? "Signing Up for our newsletter ..." : "Sign up for our newsletter"}
                </Button>
              </FormSubmitSection>
            </Form>
          )}
        </div>
      </div>
      <div className="rights-section">
        <p>
          SEVEN SEVEN SIX © {new Date().getFullYear()} 776 Fund Management, 776 logo, Heptagon/Heptagon/Hexagon Logo,
        </p>
        <p>and “Seven Seven Six” are all trademarks of 776 Fund Management. All Rights Reserved.</p>
      </div>
    </FooterSection>
  );
};

export default Footer;
