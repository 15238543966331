const colors = {
  layoutDark: "#000",
  layoutLight: "#FFF",
  layoutNeutral: "#F2F2F2",
  layoutMedium: "#D8DADD",

  textPrimary: "#172032",
  textSecondary: "#505562",
  textDisabled: "#A1A5AC",
  brandColor: "#776BCE",

  errorColor: "#CE6B6B",
};

export default colors;
