import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { rgba } from "polished";
import { motion } from "framer-motion";

const MobileNavigationList = styled(motion.ul)<{ isMenuOpen: boolean }>`
  margin: 0;
  padding: 100px 39px;
  width: ${({ isMenuOpen }) => (isMenuOpen ? "calc(100vw + 15px)" : "0")};
  height: 100vh;

  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 10;

  flex-direction: column;
  gap: 35px;

  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.brandColor} 50%,
    ${({ theme }) => rgba(theme.colors.brandColor, 0)} 218.24%
  );

  list-style: none;
  pointer-events: all;

  color: ${({ theme }) => theme.colors.layoutLight};
  font-size: calc(39rem / 16);
  font-weight: 600;
  line-height: calc(39rem / 16);

  li {
    width: fit-content;
    a {
      opacity: 1;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.layoutDark};
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    display: flex;
  }
`;

interface MobileNavigationProps {
  isMenuOpen: boolean;
  closeMenu: () => void;
}

const MobileNavigation = ({ isMenuOpen, closeMenu }: MobileNavigationProps) => {
  const location = useLocation();

  return (
    <MobileNavigationList
      isMenuOpen={isMenuOpen}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key="mobile-navigation"
      transition={{ duration: 0.3, ease: "easeOut" }}
    >
      <li>
        <Link
          to="/"
          onClick={closeMenu}
          style={{
            color: location.pathname === "/" && location.hash !== "#join-us" && "#172032",
          }}
        >
          Home
        </Link>
      </li>
      <li>
        <Link
          to="/team"
          onClick={closeMenu}
          style={{
            color: location.pathname === "/team" && location.hash !== "#join-us" && "#172032",
          }}
        >
          Team
        </Link>
      </li>
      <li>
        <Link
          to="/portfolio"
          onClick={closeMenu}
          style={{
            color: location.pathname === "/portfolio" && location.hash !== "#join-us" && "#172032",
          }}
        >
          Portfolio
        </Link>
      </li>
      <li>
        <Link
          to="/news"
          onClick={closeMenu}
          style={{
            color: location.pathname === "/news" && location.hash !== "#join-us" && "#172032",
          }}
        >
          News
        </Link>
      </li>
      <li>
        <Link to="/careers" onClick={closeMenu}>
          Careers
        </Link>
      </li>
      <li>
        <Link to="/drops" onClick={closeMenu}>
          Drops
        </Link>
      </li>
      <li>
        <Link
          to="#sign-up"
          onClick={closeMenu}
          style={{
            color: location.hash === "#join-us" && "black",
          }}
        >
          Sign Up
        </Link>
      </li>
      <Link to="/" onClick={closeMenu}>
        <img src="/icons/heart.svg" alt="" width={26} height={26} />
      </Link>
    </MobileNavigationList>
  );
};

export default MobileNavigation;
