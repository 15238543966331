import { API_URL } from "config/Config";
import { useCallback, useState } from "react";
import { processServerResponse } from "utils/httpUtils";

export interface PortfolioItem {
  emoji: string;
  name: string;
  website?: string;
}

const usePortfolio = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<PortfolioItem[]>([]);

  const loadPortfolio = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/sevensevensix/portfolio`, {
        method: "GET",
      });
      const data = await processServerResponse(response);
      setItems((data || []).sort(() => Math.random() - 0.5));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  // randomize the order of the items

  return {
    items,
    loading,
    loadPortfolio,
  };
};

export default usePortfolio;
