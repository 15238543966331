import { API_URL } from "config/Config";
import { createRef, useState } from "react";
import { processServerResponse } from "utils/httpUtils";
import { validateEmail } from "utils/utils";

const useSignUp = () => {
  const [email, setEmail] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState<string>(null);
  const [emailError, setEmailError] = useState(false);
  const [success, setSuccess] = useState(false);

  const recaptchaRef = createRef();
  
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleInstagramChange = (event) => {
    setInstagram(event.target.value);
  };

  const handleTwitterChange = (event) => {
    setTwitter(event.target.value);
  };

  const validateForm = () => {
    if (!email || !validateEmail(email)) {
      setEmailError(true);
      setErrors("Invalid email format. Please enter a valid email address");
      return false;
    }

    setEmailError(false);
    setErrors(null);
    return true;
  }

  const clear = () => {
    setEmail("");
    setEmailError(false);
    setErrors(null);
    setTwitter("");
    setInstagram("");
  }

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setSaving(true);
    try {
      //@ts-ignore
      const token = await recaptchaRef.current.executeAsync();
      if (!token) {
        console.error("Google Recaptcha token is missing");
        setErrors("Google Recaptcha token is missing");
        return;
      }

      const payload = { email, instagram, twitter };

      if (!payload.instagram.trim()) {
        delete payload.instagram;
      }

      if (!payload.twitter.trim()) {
        delete payload.twitter;
      }

      const response = await fetch(`${API_URL}/sevensevensix/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      await processServerResponse(response);
      setSuccess(true);
      clear();
    } catch (e) {
      if (e.httpStatus === "409") {
        setEmailError(true);
        setErrors("You're already on our list!");
      }
      setErrors("Sorry. There was an error. Please reload page and try again.");
      console.error(e);
    } finally {
      setSaving(false);
    }
  }

  return {
    email,
    saving,
    errors,
    twitter,
    success,
    instagram,
    emailError,
    recaptchaRef,
    onSubmit,
    handleTwitterChange,
    handleEmailChange,
    handleInstagramChange,
  }
};

export default useSignUp