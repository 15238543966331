import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";

import App from "./App";
import { ENVIRONMENT, SENTRY_DSN } from "config/Config";

import "./assets/styles/main.scss";

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));
