import { FC, useEffect } from "react";
import styled from "styled-components";
import { getBaseUrl } from "utils/utils";
import { Helmet } from "react-helmet";
import { APP_TITLE } from "config/Config";
import useScrollToTop from "components/common/useScrollToTop";
import FadeUp from "components/common/FadeUp";
import useCounter from "components/common/useCounter";
import useScreenSize from "components/common/useScreenSize";
import { Header } from "components";
import Shape from "components/common/Shape";
import usePortfolio from "components/usePortfolio";

const PortfolioDiv = styled.div`
  max-width: 1740px;
  margin: 0 auto;
  padding: 175px 70px 90px 70px;

  .content-wrapper {
    position: relative;
    z-index: 1;
  }

  h2 {
    width: 1100px;
    max-width: 100%;
    font-size: 40px;
    font-weight: 400;
    line-height: 134%;
    color: ${({ theme }) => theme.colors.textPrimary};
  }

  .items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    margin-top: 50px;
  }

  .portfolio-item-wrapper {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  @media all and (min-width: 1740px) {
    .portfolio-item-wrapper {
      &:nth-last-child(-n + 2) {
        border-bottom: none;
      }
      &:nth-child(5n) {
        border-right: none;
      }
    }
  }
  @media all and (max-width: 1740px) and (min-width: 1415px) {
    .portfolio-item-wrapper {
      &:nth-last-child(-n + 4) {
        border-bottom: none;
      }
      &:nth-child(4n) {
        border-right: none;
      }
    }
  }
  @media all and (max-width: 1415px) and (min-width: 1100px) {
    .portfolio-item-wrapper {
      &:nth-last-child(-n + 3) {
        border-bottom: none;
      }
      &:nth-child(3n) {
        border-right: none;
      }
    }
  }
  @media all and (max-width: 1100px) and (min-width: 770px) {
    .portfolio-item-wrapper {
      &:nth-last-child(-n + 2) {
        border-bottom: none;
      }
      &:nth-child(2n) {
        border-right: none;
      }
    }
  }
  @media all and (max-width: 770px) {
    .portfolio-item-wrapper {
      border-right: none;
      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  .portfolio-item {
    min-width: 320px;
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    background-color: transparent;
    border: none;
    outline: none;

    .icon {
      font-size: 28px;
    }

    .title {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.textPrimary};
    }

    .url {
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.textSecondary};
    }

    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.05);
      cursor: pointer;
      box-shadow: none;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    padding: 115px 25px 75px 25px;
    h2 {
      font-size: 30px;
    }

    .items {
      display: block;
      width: 100%;
    }

    .portfolio-item {
      width: 100%;
      height: 120px;
      .icon {
        width: 21px;
      }

      .title {
        margin-top: 5px;
        font-size: 14px;
      }

      .text {
        font-size: 14px;
      }

      .url {
        font-size: 14px;
      }
    }
  }
`;

const Portfolio: FC = () => {
  useScrollToTop();
  const { isMobile } = useScreenSize();
  const { items, loading, loadPortfolio } = usePortfolio();
  const animationIndex = useCounter(items.length, 100);

  useEffect(() => {
    loadPortfolio();
  }, [loadPortfolio]);

  if (loading && !items.length) return null;

  return (
    <PortfolioDiv>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} — The 776 Community is making things people love.</title>
      </Helmet>

      <Shape />

      <div className="content-wrapper">
        <Header showShape />

        <h2>The 776 Community is making things people love.</h2>

        <div className="items">
          {items.map((item, index) => (
            <FadeUp key={item.name} show={isMobile || animationIndex > index} className="portfolio-item-wrapper">
              <a href={item.website} target="_blank" rel="noreferrer">
                <button className="portfolio-item">
                  <div className="icon">{item.emoji}</div>
                  <div className="title">{item.name}</div>
                  <div className="url">{getBaseUrl(item.website)}</div>
                </button>
              </a>
            </FadeUp>
          ))}
        </div>
      </div>
    </PortfolioDiv>
  );
};

export default Portfolio;
