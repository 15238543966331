import { Link } from "react-router-dom";
import { rgba } from "polished";
import styled from "styled-components";
import { FC, useMemo } from "react";
import blogPosts, { BlogPost } from "blog";
import { RoutesEnum } from "utils/Routes";

const NavigationSection = styled.div`
  display: flex;
  width: 100%;
  max-width: 1740px;
  padding: 0 70px;
  margin: 0 auto;

  .title {
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: calc(16rem / 16);
    font-weight: 600;
    line-height: calc(22rem / 16);
  }

  .author {
    color: ${({ theme }) => theme.colors.textDisabled};
    font-size: calc(13rem / 16);
    font-weight: 400;
    line-height: calc(20rem / 16);
  }

  .content-section {
    max-width: 340px;
    flex-shrink: 2;
    display: flex;
    flex-direction: column;
    gap: 5px;

    p {
      margin: 0;
    }
  }

  .prev-news {
    border-right: 1px solid ${({ theme }) => theme.colors.layoutMedium};
  }

  .prev-news,
  .next-news {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex: 1;
    display: flex;
    gap: 15px;
    position: relative;
    z-index: 0;
    &:after {
      width: 100%;
      content: " ";
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      height: 1px;
      z-index: 0;
      background-color: #e0e0e0;
    }

    .news-image {
      width: 65px;
      height: 65px;
      position: relative;
      flex-shrink: 0;
      .img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }

      img {
        object-fit: cover;
        display: block;
      }

      .arrow {
        width: 65px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: ${({ theme }) => theme.colors.layoutMedium};
        transition: all 0.3s ease-in-out;
        opacity: 0;
        img {
          display: block;
        }
      }
    }

    &:hover {
      background-color: ${({ theme }) => rgba(theme.colors.textPrimary, 0.06)};
      .news-image .arrow {
        opacity: 1;
      }
    }
  }

  .next-news {
    justify-content: flex-end;
    text-align: end;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    flex-direction: column;
    gap: 0px;
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
    margin-bottom: 0;
    padding: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.layoutMedium};
    .prev-news,
    .next-news {
      padding: 20px;
      border: none;
    }

    .next-news {
      border-top: 1px solid ${({ theme }) => theme.colors.layoutMedium};
      flex-direction: row-reverse;
      justify-content: flex-end;

      .content-section {
        text-align: start;
        align-items: flex-start;
      }
    }
  }
`;

interface NewsItemNavigationProps {
  id: string;
}

const NewsItemNavigation: FC<NewsItemNavigationProps> = ({ id }) => {
  const prevNews: BlogPost = useMemo(() => {
    const currentIndex = blogPosts.findIndex((post) => post.id === id);
    let index = currentIndex - 1;
    if (index < 0) index = blogPosts.length - 1;
    return blogPosts[index];
  }, [id]);

  const nextNews: BlogPost = useMemo(() => {
    const currentIndex = blogPosts.findIndex((post) => post.id === id);
    let index = currentIndex + 1;
    if (index > blogPosts.length - 1) index = 0;
    return blogPosts[index];
  }, [id]);

  return (
    <NavigationSection>
      <Link className="prev-news" to={RoutesEnum.newsView(prevNews.id)}>
        <div className="news-image">
          <img src={prevNews.image} alt="" className="img" width={65} height={65} />
          <div className="arrow">
            <img src="/icons/left-arrow.svg" alt="Next" width={24} height={24} />
          </div>
        </div>
        <div className="content-section">
          <p className="title">{prevNews.title}</p>
          <p className="author">By {prevNews.author}</p>
        </div>
      </Link>
      <Link className="next-news" to={RoutesEnum.newsView(nextNews.id)}>
        <div className="content-section">
          <p className="title">{nextNews.title}</p>
          <p className="author">By {nextNews.author}</p>
        </div>
        <div className="news-image">
          <img src={nextNews.image} alt="" className="img" width={65} height={65} />
          <div className="arrow">
            <img src="/icons/right-arrow.svg" alt="Next" width={24} height={24} />
          </div>
        </div>
      </Link>
    </NavigationSection>
  );
};

export default NewsItemNavigation;
