import NewsCard from "../components/news/NewsCard";
import { FC } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import LastPost from "components/news/LastPost";
import blogPosts from "blog";
import { RoutesEnum } from "utils/Routes";
import { Helmet } from "react-helmet";
import { APP_TITLE } from "config/Config";
import useScrollToTop from "components/common/useScrollToTop";
import { Header } from "components";
import Shape from "components/common/Shape";
import Button from "components/common/Button";

const NewsWrapper = styled.section`
  max-width: 1740px;
  width: 100%;
  margin: 0 auto;
  padding: 175px 70px 170px 70px;
  background-color: ${({ theme }) => theme.colors.layoutNeutral};
  display: flex;
  flex-direction: column;
  justify-content: center;

  .subscribe-text {
    width: 610px;
    max-width: 100%;
    padding: 130px 25px;
    margin: 0 auto;
    color: ${({ theme }) => theme.colors.layoutDark};
    font-size: 26px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    .button-wrapper {
      margin-top: 30px;
    }
  }

  .content-wrapper {
    position: relative;
    z-index: 1;
  }

  .news-cards-container {
    display: flex;
    flex-direction: column;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    padding: 115px 0 75px 0;

    .news-cards-container {
      padding: 0px 25px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    .news-cards-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const News: FC = () => {
  useScrollToTop();

  return (
    <NewsWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} — Subscribe now for a regular dose of insightful content delivered to your inbox</title>
      </Helmet>

      <Shape />

      <div className="content-wrapper">
        <Header showShape />

        <LastPost post={blogPosts[0]} />

        <p className="subscribe-text">
          Subscribe now for a regular dose of insightful content delivered to your inbox
          <div className="button-wrapper">
            <Link to="#sign-up">
              <Button dark>Subscribe</Button>
            </Link>
          </div>
        </p>

        <div className="news-cards-container">
          {blogPosts.slice(1).map((post, index) => (
            <Link key={post.id} to={RoutesEnum.newsView(post.id)}>
              <NewsCard post={post} />
            </Link>
          ))}
        </div>
      </div>
    </NewsWrapper>
  );
};

export default News;
