import Image from "assets/images/blog/Low-poly-portraits-Katelin-Alexis-Lissie-e1670198296341-1024x616.png";
import { Link } from "react-router-dom";

const outOfTheBlocks = {
  id: "out-of-the-blocks",
  author: "Alexis Ohanian",
  date: new Date("06/10/2021"),
  title: "Out Of The Blocks",
  image: Image,
  text: (
    <>
      <h3>
        <p className="bold-text">
          Back in February, I wrote that we were at the starting line of Seven Seven Six, with the ambitious goal of
          starting a different kind of venture firm—built on software and created with intention. Today, I'm proud to
          announce the oversubscribed close of our first $150 million core fund.
        </p>
      </h3>
      <p></p>
      <p>
        The last year was one of the most transformative years of my life, much of which was set in motion by the tragic
        and despicable murder of George Floyd.
      </p>
      <p></p>
      <p>
        Like many people, it forced necessary introspection and led me to identify how I could be a better person,
        husband and father. In the immediate aftermath, I made a series of life-altering decisions that started with
        stepping down from the board of Reddit, in protest. This past weekend marks the one year anniversary of my
        resignation. That decision had a ripple effect I never could've expected, but I'm proud to see some of the
        changes it spurred, especially as it relates to banning online hate communities and board diversification. While
        there have been advancements in the last year, there is still so much work to do and I'm far from done.
      </p>
      <p></p>
      <p>
        The drive to finish that work is what led me{" "}
        <Link target="_blank" rel="noreferrer noopener" to="https://sevensevensix.com/this-is-the-starting-line/">
          to start Seven Seven Six
        </Link>
        .
      </p>
      <p></p>
      <p>
        I've surrounded myself with people who have a shared vision and who challenge me daily. While we're a small but
        mighty team, I'm tremendously proud of what we've achieved in such a short amount of time.
      </p>
      <p></p>
      <p>
        Our{" "}
        <Link
          rel="noreferrer noopener"
          to="https://sevensevensix.com/announcing-our-2021-operators-in-residence/"
          target="_blank"
        >
          Operators in Residence Program
        </Link>{" "}
        sources the best operating partners by using an open application process that looks for not just experience, but
        also potential. Seven Seven Six founders are already raving about the quality of advice and support from our
        Operators.
      </p>
      <p></p>
      <p>
        We launched a{" "}
        <Link
          rel="noreferrer noopener"
          to="https://sevensevensix.com/the-seven-seven-six-2-growth-caregiving-commitment/"
          target="_blank"
        >
          2% Growth and Caregiving Commitment
        </Link>
        , which affirms our investment in our founders-not just their companies, but in their well-being. If we take
        care of our founders, we believe they'll take care of their people, and we'll all be more successful as a
        result.
      </p>
      <p></p>
      <p>
        I'm delighted to be creating software again. Our firm is powered by an operating system I've named{" "}
        <Link rel="noreferrer noopener" to="https://en.wikipedia.org/wiki/Cerebro" target="_blank">
          Cerebro
        </Link>
        . You've probably seen me tweeting out cryptic demo videos so far, but at six months in, we're six months in,
        it's where our team does the bulk of their work, asynchronously. Our founders all have a login as well, which
        plugs them into a searchable database of over 40,000 contacts where they're one-click away from an introduction.
        We believe that software should do all the work of a venture firm that humans aren't amazing at, like data
        recall. Tools like Cerebro allows us to spend our time working as a team, with founders, or with LPs doing
        creative, strategic, and empathetic work.
      </p>
      <p></p>
      <p>
        Finally, we want to generate outsized returns and do so for an investor base that is a reflection of the United
        States, so we set out to get 50% female, and 15% Black or Indigenous people as LPs. This was much harder than I
        expected, but it was well worth the effort. In the process, we met scores of very motivated investors—from
        university endowments to individuals—who were excited by our ambition, many of whom in 10 years of fundraising,
        I'd never met and probably never would've met.
      </p>
      <p></p>
      <p>
        As investors, we started winning our first deals last summer—before we even had a fund raised—and will always be
        grateful to those founders for seeing our vision so early.{" "}
        <Link rel="noreferrer noopener" to="https://sevensevensix.com/portfolio/" target="_blank">
          You can find them all here
        </Link>
        . New companies are announced on our twitter{" "}
        <Link rel="noreferrer noopener" to="http://twitter.com/sevensevensix.com" target="_blank">
          @SevenSevenSix.
        </Link>
      </p>
      <p></p>
      <p>
        This is by no means the finish line — if anything, we've only just left the starting blocks. There is much more
        to come from Seven Seven Six and I'm grateful to everyone who has helped get us to this point.
      </p>
      <p></p>
    </>
  ),
};

export default outOfTheBlocks;
