import { TeamCard } from "team";
import styled from "styled-components";
import { motion } from "framer-motion";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { ReactComponent as AngelListIcon } from "../../assets/icons/angellist.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/icons/linkedin.svg";
import { ReactComponent as TikTokIcon } from "../../assets/icons/tiktok.svg";
import { ReactComponent as WebsiteIcon } from "../../assets/icons/website.svg";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RoutesEnum } from "utils/Routes";
import { Link } from "react-router-dom";

const SocialIcons = {
  twitter: XIcon,
  linkedin: LinkedInIcon,
  instagram: InstagramIcon,
  tiktok: TikTokIcon,
  angellist: AngelListIcon,
  web: WebsiteIcon,
};

const TeamMemberPreviewDiv = styled(motion.div)`
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;

  p {
    margin: 0px;
  }

  .member-info {
    flex: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.layoutNeutral};

    & > div {
      padding: 60px 81px;
      max-width: 50rem;
      display: flex;
      flex-direction: column;
      margin: auto;

      .close-button {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
        border: none;
        outline: none;
        background-color: transparent;
        transition: all ease 0.3s;
        z-index: 11;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .member-picture-container {
    display: block;
    height: 100%;
    flex: 1 1 40%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .member-picture {
      width: 493px;
      height: 572px;
      object-fit: contain;
      z-index: 9;
    }

    @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
      display: none;
    }
  }

  .member-name {
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: 65px;
    font-weight: 600;
    line-height: 1;

    margin-bottom: 30px;
  }

  .member-position {
    display: block;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: 21px;
    font-weight: 600;
    line-height: 1.5;

    margin-bottom: 60px;
  }

  .about-member {
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: 17px;
    font-weight: 400;
    line-height: 1.65;
    cursor: auto;

    padding-bottom: 40px;

    a {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.brandColor};
    }
  }

  .member-socials {
    display: flex;
    flex-direction: row nowrap;
    column-gap: 15px;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    cursor: auto;

    .member-info {
      & > div {
        padding: 100px 28px;
        max-width: 100%;
        display: block;
        justify-content: unset;

        .close-button {
          position: absolute;
          top: 10px;
          right: 10px;
          display: block;
          min-width: 54px;
          min-height: 54px;
          z-index: 11;
        }
      }
    }

    .member-name {
      font-size: 40px;
      margin-bottom: 25px;
    }

    .member-position {
      font-size: 16px;
      margin-bottom: 40px;
    }

    .about-member {
      font-size: 15px;
      line-height: 1.73;
      color: ${({ theme }) => theme.colors.textSecondary};
      a {
        text-decoration: none;
      }
    }

    .member-socials {
      column-gap: 10px;
    }
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 75px;
  height: 75px;
  circle {
    fill: ${({ theme }) => theme.colors.layoutNeutral};
  }
`;

const StyledSocialLink = styled(Link)`
  svg {
    circle {
      stroke: ${({ theme }) => theme.colors.layoutDark};
    }

    path[stroke] {
      stroke: ${({ theme }) => theme.colors.layoutDark};
    }
    path[fill] {
      fill: ${({ theme }) => theme.colors.layoutDark};
    }

    &:hover {
      path[stroke] {
        transition: stroke 0.3s ease-in-out;
        stroke: ${({ theme }) => theme.colors.layoutLight};
      }
      path[fill] {
        transition: fill 0.3s ease-in-out;
        fill: ${({ theme }) => theme.colors.layoutLight};
      }
      transition: fill 0.3s ease-in-out;
      fill: ${({ theme }) => theme.colors.layoutDark};
    }
  }
`;

interface TeamMemberPreviewProps {
  items: TeamCard[];
}

const TeamMemberPreview: FC<TeamMemberPreviewProps> = ({ items = [] }) => {
  const navigate = useNavigate();

  const params = useParams();
  const member = useMemo(() => items.find((member) => member.id === params.id), [items, params.id]);

  useEffect(() => {
    document.body.style.touchAction = "none";
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.touchAction = "auto";
      document.body.style.overflowY = "auto";
    };
  }, []);

  const onClose = useCallback(() => {
    navigate(RoutesEnum.team);
  }, [navigate]);

  if (!member) return null;

  return (
    <TeamMemberPreviewDiv
      key="popup"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      layoutId="member-popup"
    >
      <div className="member-info">
        <div>
          <button className="close-button" onClick={onClose}>
            <StyledCloseIcon />
          </button>
          <p className="member-name">
            {member?.name.split(" ").map((word) => (
              <span key={word}>
                {word} <br />
              </span>
            ))}
          </p>
          <p className="member-position" key="alexis-position">
            {member?.position}
          </p>
          <p
            className="about-member"
            dangerouslySetInnerHTML={{ __html: member?.text }}
            onClick={(e) => e.stopPropagation()}
          ></p>
          {!!member.socials && (
            <div className="member-socials">
              {(Object.keys(member.socials) as (keyof TeamCard["socials"])[]).map((network) => {
                const SocialIcon = SocialIcons[network];
                return (
                  <StyledSocialLink
                    key={network}
                    to={member.socials[network]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SocialIcon />
                  </StyledSocialLink>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="member-picture-container">
        <motion.img layoutId={member?.id} src={member?.image} alt="" className="member-picture" />
      </div>
    </TeamMemberPreviewDiv>
  );
};

export default TeamMemberPreview;
