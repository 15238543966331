import { Link } from "react-router-dom";

import Image from "assets/images/blog/Low-poly-talent-outcomes-team-application-process.png";
import ChrisVanzetta from "assets/images/blog/TheTalentOutcomes.png";

const talentOutcomes = {
  id: "the-talent-outcomes-team-application-process-and-learnings",
  author: "Katelin Holloway",
  date: new Date("08/18/2022"),
  title: "The Talent Outcomes Team Application Process & Learnings",
  image: Image,
  imageSource: "Original art by Guss Navarro",
  text: (
    <>
      <h3>
        <p className="bold-text">
          If I've said it once, I've said it a thousand times: people and culture are at the heart of everything we do
          here at Seven Seven Six. From our founders and their teams, our investors and the communities they support, to
          this first generation of firm employees, nothing is more important than the humans dedicated to making change
          in this world.&nbsp;
        </p>
      </h3>
      <p>
        Closing our second core fund in late 2021 enabled us to bring our vision for the 776 Talent Outcomes team into
        reality. While it's still in early days, we're excited to share more about what this budding team has been
        working on, takeaways from our{" "}
        <Link to="https://sevensevensix.com/careers/talent-outcomes-team/" target="_blank" rel="noopener noreferrer">
          recruiting experiment
        </Link>{" "}
        this last spring, and what's on deck.
      </p>
      <p></p>
      <h2>What is Talent Outcomes?</h2>
      <p></p>
      <p>
        <strong>
          The Talent Outcomes team is a critical function that supports our portfolio companies with human resources,
          talent acquisition, DEIB efforts, and culture development strategy.
        </strong>
      </p>
      <p>
        We track every single incoming ask from our founders through our operating software Cerebro. What's at the top
        of that list? You've guessed it: People &amp; Culture requests. Through this data, we quickly understood it was
        imperative we establish a team to help founders build thriving, sustainable, and inclusive organizations. And
        it's through our decades of experience as operators that we know we can do this work more effectively and
        efficiently than anyone else.
      </p>
      <p>
        We're committed to pushing our industry forward, and how we think about Talent in Venture is no exception.
        Follow us on{" "}
        <Link to="https://www.linkedin.com/company/sevensevensix/" target="_blank" rel="noopener noreferrer">
          LinkedIn
        </Link>{" "}
        and{" "}
        <Link to="https://twitter.com/sevensevensix" target="_blank" rel="noopener noreferrer">
          Twitter
        </Link>{" "}
        for more on our perspective soon.
      </p>
      <p></p>
      <h2>Recruiting for the Talent Outcomes Team</h2>
      <p></p>
      <p>
        Back in April, we decided to run a recruiting experiment. We were inspired by questions we'd heard several times
        from our early-stage founders seeking help with their talent needs:&nbsp;
      </p>
      <ul>
        <li>
          <em>How do I recruit for a role when I'm open to the level or years of experience a candidate may have?</em>
        </li>
        <li>
          <em>
            What if I want to hire a few people for a newly formed team? It's basically the same job description, right?
          </em>
        </li>
        <li>
          <em>
            I found more than one candidate that I love during the interview process and I think they'd work well
            together. Can I hire more than one person?
          </em>
        </li>
      </ul>
      <p>
        Being curious ourselves as to how startup hiring may play out in this new work era, we decided to try something
        different: instead of posting individual job requisitions for each potential role on the Talent Outcomes team,
        we decided to post a general application. Because this was a new function at the firm with many configuration
        possibilities, we chose to cast the widest possible net. We were curious what would happen when we intentionally
        broke our best practice.
      </p>
      <p>That said, there were core pillars we wanted to maintain as a part of our recruiting process:</p>
      <ul>
        <li>
          <strong>Open. </strong>Publicly posting job requisitions in VC is still incredibly rare. Because we believe
          that the best way to maximize returns is to have a diverse team with a wide variety of perspectives and
          experiences, we continue to host open applications for every role.
        </li>
      </ul>
      <p></p>
      <ul>
        <li>
          <strong>Inclusive. </strong>We've been building high-performing, diverse teams for well over a decade. When it
          comes to inclusion, we know that language and clarity of the role matter. With every job posting to date, we
          clearly state what the role is and what the role is not. In this wide-net approach, we adjusted to reflect
          what the{" "}
          <strong>
            <em>function</em>
          </strong>{" "}
          is and is not. As always, we encouraged interested candidates to apply, even if they felt they didn't meet all
          the requirements.&nbsp;
        </li>
      </ul>
      <p></p>
      <ul>
        <li>
          <strong>Useful. </strong>Striving for the win-win-win scenario, we craft every job application with care. We
          want applicants to learn something about themselves and find clarity in their own journeys through our
          process. Every task or question needed to serve as an opening for personal growth. Anything we ask of an
          applicant is meant to be something they could use as an exercise in self-reflection or in building their
          personal brand.&nbsp;
        </li>
      </ul>
      <p></p>
      <ul>
        <li>
          <strong>Cloaked. </strong>We developed a process during our{" "}
          <Link
            to="https://sevensevensix.com/the-operator-in-residence-application-process-learnings/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Operator-in-Residence
          </Link>{" "}
          application evaluation that helped us to view candidates' initial responses without their name, title, or
          pedigree associated. Just the raw responses to the open-ended prompts in the application. We very genuinely
          don't care about resumes, where people went to school (or even if they did), or who they know. We wanted to
          maintain this practice in this application process, as well.
        </li>
      </ul>
      <p>
        So, we posted the role with open minds, hoping to gain new insights. We received over 250 applications and began
        our assessment process.&nbsp;As with all things we try here at Seven Seven Six, we are choosing to share those
        learnings here, in hopes that you all can find some sort of value in your own work.
      </p>
      <p></p>
      <h3>Lessons Learned</h3>
      <p>
        We must admit, shifting our recruiting approach provided more challenges and learnings than we had expected.
      </p>
      <p></p>
      <p>
        <strong>Where we could have done better:</strong>
      </p>
      <ul>
        <li>
          <strong>
            Having so many different types of applicants with a wide range of levels and areas of expertise made it
            incredibly difficult for us to prioritize and evaluate what we were actually hiring for.
          </strong>{" "}
          The hope was that reading through a variety of applications would provide clarity on what we were looking for
          in this early team development. The reality is that it offered more confusion, demonstrating the obvious need
          for individual job postings that clearly articulate the role, approximate level, specific skill sets needed,
          and - yes - compensation.
        </li>
      </ul>
      <p></p>
      <ul>
        <li>
          <strong>
            We overestimated the time savings we thought we'd earn back by having one application for multiple roles.
          </strong>{" "}
          We took much longer than we had planned to evaluate the applications, turn around feedback, and invite
          candidates to interview with the team. The result is that it took us double the time and we only hired one
          person, creating the need to go back to the market and start a new process from scratch to grow the team
          further.
        </li>
      </ul>
      <p></p>
      <ul>
        <li>
          <strong>We still need to work on broadening our amplification channels for posting jobs.</strong> When it came
          down to our top applicant pool, we discovered that many candidates were within our first degree networks.
          Speaking personally, this particular job posting was certain to host many familiar faces; People &amp; Culture
          is my strongest community. As the hiring manager, I was genuinely surprised to see the names of the finalists
          once they were revealed. The list included former managers, mentors, friends, coaches, former colleagues -
          heck, even my aunt made the first round cut, unbeknownst to me. I was torn when I saw these results: happy to
          have so many talented people in my life that wanted to work at our firm, but also frustrated that we didn't
          reach more people. There must be more than a handful of HR professionals I don't know (yet)!
        </li>
      </ul>
      <p></p>
      <h3>What we committed to:</h3>
      <p>
        We also want to call out the things that were really, really hard but worth the effort. We were challenged at
        times and debated wavering. We're glad we didn't.&nbsp;
      </p>
      <ul>
        <li>
          <strong>We rejected referrals. </strong>We continue to simply offer{" "}
          <Link to="https://sevensevensix.com/careers/" target="_blank" rel="noopener noreferrer">
            our careers website
          </Link>{" "}
          to apply and leave it at that.
        </li>
      </ul>
      <p></p>
      <ul>
        <li>
          <strong>We didn't second-guess our process.</strong> This was an experimental job posting and application
          process. Much as we may have wanted to shift course midway through, it was important to understand all of the
          learnings on the backend so that we could ultimately better support our founders.
        </li>
      </ul>
      <p></p>
      <p>
        <strong>What worked?</strong>
      </p>
      <p>For all of our failures and learnings in this process, we're very proud of our results.</p>
      <ul>
        <li>
          <strong>We maintained our early—and now best!—practices. </strong>By keeping open applications, writing
          inclusive and clear job descriptions, seeking to provide utility in each process, and hiding personal
          identifiers in the initial evaluation, we were reminded of why challenging status quo hiring practices is
          something we love. We continue to find joy in getting to know our candidates in this way and are rapidly
          growing our networks of incredible, capable humans. These commitments take time and energy, investments we'll
          continue to make.
        </li>
      </ul>
      <p></p>
      <ul>
        <li>
          <strong>We decided to start with a leader. </strong>After much hemming and hawing, we finally made the
          realization that we needed a true industry leader with deep People &amp; Culture expertise to help us build
          the roadmap for this team. Building the team haphazardly without a vision wouldn't set anyone up for success.
          Once we made this distinction, we reread all of the applications and were able to quickly pull out the
          candidates that we wanted to learn more about.
        </li>
      </ul>
      <p></p>
      <h2>Meet Our New Talent Outcomes Leader</h2>
      <p>
        With all of that, we're excited to share that we have found someone phenomenal to lead this team.{" "}
        <strong>
          Our new Talent Outcomes Partner is{" "}
          <Link
            to="https://www.linkedin.com/in/christopher-vanzetta-99209712b/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chris Vanzetta
          </Link>
          .
        </strong>{" "}
        Chris has built an incredible career leading HR &amp; Talent teams for over a decade at companies like Target
        and Reddit. He knows firsthand the challenges of building young organizations from inception through
        hyper-growth.{" "}
      </p>
      <img decoding="async" loading="lazy" src={ChrisVanzetta} alt="" />
      <p></p>
      <p>
        We cannot wait for you all to meet him, hear more about his vision for Talent Outcomes at Seven Seven Six, and
        learn how he plans on growing this team in the future!
      </p>
    </>
  ),
};

export default talentOutcomes;
