import { FC } from "react";
import styled from "styled-components";

const MainButton = styled.button`
  margin-top: 20px;
  margin-bottom: 20px;
  width: fit-content;
  height: fit-content;
  padding: 12px 20px;
  background-color: ${({ theme, dark }) => (dark ? theme.colors.layoutDark : "transparent")};
  border: 2px solid ${({ theme }) => theme.colors.layoutDark};
  color: ${({ theme, dark }) => (dark ? theme.colors.layoutLight : theme.colors.layoutDark)};
  border-radius: 60px;
  opacity: ${({ dark }) => (dark ? 1 : 0.5)};
  font-size: calc(21rem / 16);
  line-height: normal;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:focus {
    background-color: ${({ theme, dark }) => (dark ? "transparent" : theme.colors.layoutDark)};
    color: ${({ theme, dark }) => (dark ? theme.colors.layoutDark : theme.colors.layoutLight)};
    opacity: 1;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    width: 100%;
  }
`;

interface ButtonProps {
  children: React.ReactNode;
  type?: string;
  className?: string;
  disabled?: boolean;
  dark?: boolean;
}

const Button: FC<ButtonProps> = ({ children, type, className, disabled, dark }) => {
  return (
    <MainButton className={className} type={type} disabled={disabled} dark={dark}>
      {children}
    </MainButton>
  );
};

Button.defaultProps = {
  type: "button",
};

export default Button;
