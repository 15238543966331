import { API_URL } from "config/Config";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RoutesEnum } from "utils/Routes";
import { processServerResponse } from "utils/httpUtils";

export interface Muse {
  linkToAsset: string;
  linkToPage: string;
  linkToAssetCoverImage: string;
  linkToDownload: string;
  recorderName: string;
  watermark: boolean;
}

const useMusings = () => {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<Muse>(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const loadHype = useCallback(async (id) => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/hype/response/${id}`, {
        method: "GET",
      });
      const data = await processServerResponse(response);
      setItem(data);
    } catch (e) {
      console.error(e);
      navigate(RoutesEnum.home);
      alert("The link you are trying to access is invalid.")
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    loadHype(id);
  }, [loadHype, id]);

  return {
    item,
    loading
  };
};

export default useMusings;
