import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import MobileNavigation from "./MobileNavigation";
import { AnimatePresence } from "framer-motion";
import useScrollTop from "./common/useScrollTop";
import ScrollImages from "./common/ScrollImages";
import useScreenSize from "./common/useScreenSize";
import Shape from "./common/Shape";

const BackgroundDiv = styled.div`
  width: 100%;
  max-width: 1740px;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  @media ${({ theme }) => theme.mediaQueries.bellowDesktop} {
    justify-content: flex-start;
    padding: 0 70px;
  }
  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    padding: 0 25px;
  }
`;

const HeaderWrapper = styled.header<{ isMenuOpen: boolean; isScrolled: boolean }>`
  padding: 37px 64px 37px 76px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${(props) =>
    props.isScrolled && !props.isMenuOpen ? props.theme.colors.layoutNeutral : "transparent"};
  z-index: 10;
  transition: all 0.3s ease;
  overflow: hidden;

  &.open {
    height: 100vh;
  }

  .fixed-shape {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    max-width: 1740px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
  }

  nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1600px;
    margin: 0 auto;
    z-index: 2;
  }

  a {
    opacity: 1;
  }

  .header-list {
    list-style-type: none;
    margin: 0;

    display: flex;
    align-items: center;
    gap: 5px;
  }

  .header-list-item {
    height: fit-content;

    color: ${({ theme }) => theme.colors.layoutDark};
    font-size: calc(14rem / 16);
    font-weight: 600;
    line-height: calc(14rem / 16);
    cursor: pointer;

    a {
      padding: 10px 20px;
      display: block;
    }

    &.join-us-button {
      background-color: ${({ theme }) => theme.colors.layoutDark};

      a {
        color: ${({ theme }) => theme.colors.layoutLight};
      }
    }

    &.heart-button {
      border: none;
      background-color: transparent;
      transition: all 0.3s ease-in-out;

      @keyframes pulsate {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(0.8);
        }
        100% {
          transform: scale(1);
        }
      }
    }

    &:hover {
      transition: background-color 0.3s;
      opacity: 0.5;

      &.join-us-button {
        background-color: transparent;
        a {
          color: ${({ theme }) => theme.colors.layoutDark};
        }
      }

      &.heart-button {
        border: none;
        opacity: 1;
        background-color: transparent;
        animation: pulsate 0.5s infinite;
      }
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    padding: 38px 25px;
    .header-list {
      display: none;
    }
  }
`;

const HamburgerMenu = styled.button`
  display: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;

  position: relative;
  z-index: 11;

  * {
    transition: all 0.3s ease-in-out;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    display: block;
    width: 24px;
    height: 24px;
  }
`;

interface HeaderProps {
  lyricsSectionHeight?: number;
  showShape?: boolean;
}

const Header: FC<HeaderProps> = ({ lyricsSectionHeight, showShape }) => {
  const scrollTop = useScrollTop();
  const { isTablet } = useScreenSize();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const headerRef = useRef(null);
  useEffect(() => {
    setIsScrolled(scrollTop > 30);
  }, [scrollTop]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  const fixedShapeStyle = useMemo(() => {
    return {
      transform: `translateY(-${scrollTop < 0 ? 0 : scrollTop}px)`,
    };
  }, [scrollTop]);

  return (
    <HeaderWrapper
      ref={headerRef}
      isScrolled={isScrolled}
      isMenuOpen={isMenuOpen}
      className={isTablet && isMenuOpen && "open"}
    >
      {showShape && (
        <div className="fixed-shape" style={fixedShapeStyle}>
          <Shape justShape />
        </div>
      )}
      <nav>
        <AnimatePresence>
          {isMenuOpen && <MobileNavigation isMenuOpen={isMenuOpen} closeMenu={() => setIsMenuOpen(false)} />}
        </AnimatePresence>
        <Link to="/">
          <img src="/icons/776-logo.svg" alt="776" />
        </Link>
        <ul className="header-list">
          <li className="header-list-item">
            <Link to="/team">Team</Link>
          </li>
          <li className="header-list-item">
            <Link to="/portfolio">Portfolio</Link>
          </li>
          <li className="header-list-item">
            <Link to="/news">News</Link>
          </li>
          <li className="header-list-item">
            <Link to="/careers">Careers</Link>
          </li>
          <li className="header-list-item">
            <Link to="/drops">Drops</Link>
          </li>
          <li className="header-list-item">
            <Link to="#sign-up">Sign Up</Link>
          </li>
          <li className="header-list-item heart-button">
            <a href="https://776.org" target="_blank" rel="noreferrer">
              <img src="/icons/heart.svg" alt="" width={26} height={26} />
            </a>
          </li>
        </ul>
        <HamburgerMenu onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <svg width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.890625" y="0.480469" width="29.5791" height="2.89844" fill="#172032" />
            <rect x="0.890625" y="7.89453" width={isMenuOpen ? "29.5791" : "14.7559"} height="2.89844" fill="#172032" />
            <rect x="0.890625" y="15.3086" width="29.5791" height="2.89844" fill="#172032" />
          </svg>
        </HamburgerMenu>
      </nav>

      {!!lyricsSectionHeight && (
        <BackgroundDiv>
          <ScrollImages height={lyricsSectionHeight} />
        </BackgroundDiv>
      )}
    </HeaderWrapper>
  );
};

export default Header;
