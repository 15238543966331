import { BlogPost } from "blog";
import { FC } from "react";
import moment from "moment";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { RoutesEnum } from "utils/Routes";

const LastPostContainer = styled.div`
  color: ${({ theme }) => theme.colors.textPrimary};

  .title-section {
    margin-bottom: 67px;

    .written-by {
      margin: 0px;
      color: ${({ theme }) => theme.colors.textDisabled};
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .title {
      font-size: 55px;
      font-weight: 400;
      line-height: 1;
      margin-bottom: 30px;
      color: ${({ theme }) => theme.colors.layoutDark};
    }
  }

  .content-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;

    .news-image-wrapper {
      display: block;
      width: 100%;
      height: 100%;
      grid-column: span 1;
      .news-image {
        width: 100%;
        height: 100%;
        min-height: 200px;
        object-fit: cover;
      }
    }

    .content {
      width: 100%;
      padding-right: 90px;
      display: flex;
      flex-direction: column;
      gap: 27px;
      grid-column: span 1;

      .text {
        max-height: 276px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;

        p {
          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 46px;
        }

        p, h3 {
          margin-bottom: 0;
        }
      }
    }

    .see-more {
      cursor: pointer;
      width: 94px;
      height: 94px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      img {
        width: 50px;
        height: 50px;
        width: fit-content;
      }
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowDesktop} {
    .content-container {
      .content {
        padding-right: 20px;
      }
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    .title-section {
      padding: 0px 25px;
      margin-bottom: 40px;
      .title {
        font-size: 40px;
        margin-bottom: 20px;
      }
      .written-by {
        font-size: 16px;
      }
    }

    .content-container {
      display: flex;
      flex-direction: column;
      .content {
        padding: 0px 25px;
        max-width: 100%;
        .text {
          max-height: 228px;

          p {
            font-size: 24px;
            line-height: 38px;
            margin-bottom: 0;
          }
        }
      }

      .news-image-wrapper {
        width: 100%;
        height: auto;
      }
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    .title-section {
      margin-bottom: 20px;
      .title {
        font-size: 32px;
        font-weight: 400;
        line-height: 42px;
        margin-bottom: 20px;
      }
      .written-by {
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
      }
    }

    .content-container {
      gap: 30px;
      .content {
        gap: 25px;
        .text {
          max-height: 198px;

          p {
            font-size: 18px;
            font-weight: 400;
            line-height: 33px;
          }
        }
      }

      .news-image-wrapper {
        width: 100%;
        height: auto;
      }

      .see-more {
        width: 50px;
        height: 50px;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
`;

interface LastPostProps {
  post: BlogPost;
}

const LastPost: FC<LastPostProps> = ({ post }) => {
  return (
    <LastPostContainer>
      <div className="title-section">
        <Link to={RoutesEnum.newsView(post.id)}>
          <h1 className="title">{post.title}</h1>
        </Link>
        <p className="written-by">
          By {post.author} • {moment(post.date).format("LL")}
        </p>
      </div>
      <div className="content-container">
        <Link to={RoutesEnum.newsView(post.id)} className="news-image-wrapper">
          <img src={post.image} alt={post.title} className="news-image" />
        </Link>
        <div className="content">
          <div className="text">{post.text}</div>

          <Link to={RoutesEnum.newsView(post.id)}>
            <div className="see-more">
              <img src="/icons/right-arrow.svg" alt="See more" />
            </div>
          </Link>
        </div>
      </div>
    </LastPostContainer>
  );
};

export default LastPost;
