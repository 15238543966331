import { FC, useRef, useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { APP_TITLE } from "config/Config";
import useScrollToTop from "components/common/useScrollToTop";
import { Header } from "components";
import Shape from "components/common/Shape";
import Cap from "assets/images/drops/cap.png";
import CapHover from "assets/images/drops/cap-sticker.png";
import Sweather from "assets/images/drops/sweater.png";
import SweatherHover from "assets/images/drops/sweater-sticker.png";
import Trousers from "assets/images/drops/trousers.png";
import TrousersHover from "assets/images/drops/trousers-sticker.png";
import Gallery from "components/gallery/Gallery";
import { createPortal } from "react-dom";

const CareersContainer = styled.section`
  max-width: 1740px;
  position: relative;
  margin: 0 auto;
  padding: 175px 70px 261px 70px;
  background-color: ${({ theme }) => theme.colors.layoutNeutral};
  display: flex;
  flex-direction: column;

  .content-wrapper {
    max-width: 1740px;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .subtitle {
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  h2 {
    width: 1100px;
    max-width: 100%;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 20px;
    margin-bottom: 27px;
  }

  .slide-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 5px;
    overflow-y: hidden;
    overflow-x: auto;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;

    -ms-overflow-style: none;
    scrollbar-width: none;
    user-select: none;

    .item {
      width: 390px;
      height: 292px;
      flex-shrink: 0;

      user-select: none;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        pointer-events: none;
        user-select: none;
      }
      .image {
        display: block;
      }
      .hover {
        display: none;
      }
      &:hover {
        cursor: pointer;
        transform: rotate(-4deg);
        .hover {
          display: block;
        }
        .image {
          display: none;
        }
      }
    }

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    padding: 115px 25px 75px 25px;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    .subtitle {
      font-size: 13px;
      line-height: 1;
    }

    h2 {
      font-size: 21px;
      margin-top: 10px;
      line-height: 1;
    }

    .slide-wrapper {
      .item {
        width: 289px;
        height: 209px;
      }
    }
  }
`;

const items = [Cap, Sweather, Trousers];
const itemsHover = [CapHover, SweatherHover, TrousersHover];

const Drops: FC = () => {
  const [index, setIndex] = useState<number | null>(null);

  const sliderRef = useRef<HTMLDivElement>(null);

  useScrollToTop();

  const openGallery = (index: number) => {
    setIndex(index);
  };

  return (
    <CareersContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} — Drops</title>
      </Helmet>

      <Shape />

      <div className="content-wrapper">
        <Header showShape />

        <span className="subtitle">Winter 2023</span>
        <h2>DROP 000 // madhappy</h2>

        <div className="slide-wrapper" ref={sliderRef}>
          {items.map((item, index) => (
            <div key={item} className="item" onClick={() => openGallery(index)}>
              <img src={item} className="image" alt="" />
              <img src={itemsHover[index]} className="hover" alt="" />
            </div>
          ))}
        </div>
      </div>

      {index !== null &&
        createPortal(<Gallery images={items} index={index} onClose={() => setIndex(null)} />, document.body)}
    </CareersContainer>
  );
};

export default Drops;
