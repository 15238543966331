import { observer } from "mobx-react";
import { FunctionComponent } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";

import { routes } from "utils/Routes";
import { injectStore, WithStoreProps } from "stores/RootStore";
import Footer from "components/Footer";

const Container = styled.div``;

const Pages: FunctionComponent<WithStoreProps> = (props) => {
  const location = useLocation();

  return (
    <Container>
      <Routes>
        {Object.values(routes).map((route) => (
          <Route path={route.path} key={route.path} element={route.component} />
        ))}
      </Routes>
      {location.pathname !== "/" && <Footer />}
    </Container>
  );
};

export default injectStore(observer(Pages));
