import Image from "assets/images/blog/TheTalentOutcomes.png";
import { Link } from "react-router-dom";

const welcomeChrisVanzetta = {
  id: "welcome-chris-vanzetta",
  author: "Chris Vanzetta",
  date: new Date("09/06/2022"),
  title: "Welcome Chris Vanzetta - Talent Outcomes Partner",
  image: Image,
  imageSource: "Original art by Guss Navarro",
  text: (
    <>
      <h3>
        <p>
          <em>Because LinkedIn doesn't tell the whole story</em>
        </p>
      </h3>
      <p className="bold-text">
        Hi, y'all. Chris here, writing from a space of immense gratitude for the opportunity to lead and build the
        Talent Outcomes function at Seven Seven Six.
      </p>
      <p>
        I can't wait to tell you all about what we are building and how we are thinking about approaching things
        differently on the Talent front, but I thought it would be appropriate to share a bit about myself first. In
        true Seven Seven Six fashion, I'll let my{" "}
        <Link
          to="https://www.linkedin.com/search/results/all/?keywords=christopher%20vanzetta&amp;origin=RICH_QUERY_TYPEAHEAD_HISTORY&amp;position=0&amp;searchId=68b8fd78-b2cf-4a2b-a950-5ea327e3099d"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn page
        </Link>{" "}
        give you my resume rundown and take this space to share my story instead.
      </p>
      <p></p>
      <h2>My Love Affair with All Things People &amp; Culture</h2>
      <p>
        For those who truly know me, there's no wonder as to why I fell in love with the people function. My upbringing
        and my identity have shaped my perspective and guided me through my career. These formative experiences sparked
        the drive to build thoughtful, inclusive, and thriving workplace cultures.
      </p>
      <p>
        I am the byproduct of a low-middle income, split family from upstate New York. Both my mother and father are
        Licensed Clinical Social Workers. My dad is a practicing therapist and my mom is a care coordinator for adults
        with disabilities. Very early on, they instilled in me and my siblings that our success should be measured by
        the lives we touch, not the money we make or the status of our job. They taught me how to be in tune with my
        emotions, how to understand people and their motivations, the value of a strong work ethic, and that everyone
        has something special to offer this world.
      </p>
      <p>
        In college, I studied international business with a minor in economics and Russian studies - the latter perhaps
        being a little less practical - but I was always fascinated by the world of business; the way it is
        ever-evolving, deeply strategic, and curiously dynamic. Outside of the classroom, I ran track to exhaust my
        energy and exercise my competitive nature. But, as a gay man who was coming to terms with his identity, I
        struggled to find my sense of belonging and true community.
      </p>
      <p>
        It is this combination of experiences that have been woven into my career over time, giving me a deeper purpose
        in all that I do. My life's work is to ensure that people feel engaged, inspired, safe, and included in their
        workplaces, and to help organizations achieve success through their people strategy and practices. I have spent
        the last decade of my career directly impacting the lives of people, giving them the tools to thrive at work and
        enabling greater results through people optimization. I have helped to build several successful organizations,
        all while keeping the human experience as the primary focus. Now, I am ready to scale my work, supporting the
        776 portfolio companies as they build world-class organizations.
      </p>
      <p>I didn't set out to be an “HR Guy”, but I have to say - it feels pretty right to me.</p>
      <p></p>
      <h2>New Role, New Function</h2>
      <p>
        And so our work together begins! I can't wait to share with you how we're thinking about Talent at the firm and
        why this function is going to have a massive impact on the success of our investments. Until then, be well and{" "}
        <Link to="https://twitter.com/vanzetta_chris" target="_blank" rel="noopener noreferrer">
          follow me on Twitter
        </Link>{" "}
        for a peek behind-the-scenes of 776 and pictures of the best cat in the world - Apollo!
      </p>
    </>
  ),
};

export default welcomeChrisVanzetta;
