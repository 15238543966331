import { FC } from "react";
import styled from "styled-components";
import { ReactComponent as ShapeSvg } from "assets/images/shape.svg";

const ShapeWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  max-width: 1740px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
`;

const ShapeImage = styled(ShapeSvg)`
  @media ${({ theme }) => theme.mediaQueries.bellowDesktop} {
    width: 500px;
    height: 500px;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    width: 400px;
    height: 400px;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    width: 200px;
    height: 200px;
  }
`;

interface ShapeProps {
  justShape?: boolean;
}

const Shape: FC<ShapeProps> = ({ justShape }) => {
  if (justShape) {
    return <ShapeImage />;
  }

  return (
    <ShapeWrapper>
      <ShapeImage />
    </ShapeWrapper>
  );
};

export default Shape;
