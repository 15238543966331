import { FC, useMemo } from "react";
import styled from "styled-components";
import { formatNumber } from "utils/utils";
import { useCountUp } from "use-count-up";
import { StatCardInterface } from "enum/stats";
import clsx from "clsx";

const StatCardDiv = styled.div`
  max-width: 100%;
  flex-shrink: 0;
  padding: 40px 20px 60px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  /* mimic borders with box-shadow */
  &:after,
  &:before {
    position: absolute;
    content: "";
    inset: 0;
    width: calc(100% + 1px);
    height: calc(100% + 1px);
  }
  &:before {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  &:after {
    backdrop-filter: blur(100px);
    z-index: -1;
  }

  /* end mimic borders with box-shadow */
  .value {
    font-size: 65px;
    font-weight: 600;
    line-height: 1.2;
    font-family: "Archivo Narrow", sans-serif;
    color: ${({ theme }) => theme.colors.layoutDark};
    small {
      font-size: 50px;
    }
  }

  span {
    display: block;
    height: 75px;
    font-size: 17px;
    font-weight: 500;
    line-height: normal;
    color: ${({ theme }) => theme.colors.textSecondary};
    line-height: normal;
    text-transform: uppercase;
    small {
      font-size: 14px;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    padding-bottom: 35px;
    .value {
      font-size: 60px;
    }

    span {
      height: 55px;
      font-size: 14px;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    padding: 25px 15px 25px 15px;
    .value {
      font-size: 46px;
      small {
        font-size: 35px;
      }
    }

    span {
      height: 65px;
      font-size: 12px;
    }
  }
`;

const StatCard: FC<StatCardInterface> = ({ className, isString, value, additionalText, text, colSpan }) => {
  const duration = useMemo(() => {
    const length = (value + "").length;
    if (length < 3) return 3;
    if (length > 7) return 7;
    return length;
  }, [value]);

  const { value: counterValue } = useCountUp({
    isCounting: true,
    duration: duration,
    end: Number(value),

    formatter: formatNumber,
  });

  const parsedValue = useMemo(() => {
    if (isString) return value;

    return !value ? 0 : counterValue;
  }, [isString, value, counterValue]);

  const colSpanClasses = useMemo(() => {
    if (!colSpan) {
      return "";
    }

    return Object.keys(colSpan).map((key) => {
      return ` ${key}-${colSpan[key]}`;
    });
  }, [colSpan]);

  return (
    <StatCardDiv className={clsx(className, colSpanClasses)}>
      <span>{text}</span>
      <div className="value">
        {parsedValue}
        {!!additionalText && <small>{additionalText}</small>}
      </div>
    </StatCardDiv>
  );
};

export default StatCard;
