import { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as LastImg } from "assets/images/homepage/40.svg";

const LyricsSectionDiv = styled.section`
  width: 100%;
  margin: 0 auto;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  padding: 290px 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.textDisabled};
  transition: color 1s ease;
  position: relative;
  .active {
    color: ${({ theme }) => theme.colors.layoutDark};
    transition: all 0.3s ease-in-out;
  }

  .spacer {
    display: none;
  }

  .svg-logo {
    display: block;
    width: 140px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowDesktop} {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    .spacer {
      display: block;
    }
    .svg-logo {
      width: auto;
      position: relative;
      margin: 0;
    }
    .spacer,
    .svg-logo {
      height: 60px;
      margin-bottom: 30px;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    padding: 115px 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    max-width: 100%;
    font-size: 19px;
    font-weight: 500;
    line-height: 137%;
    padding: 115px 0;
    .spacer,
    .svg-logo {
      height: 30.65px;
      margin-bottom: 30px;
    }
  }
`;

const lyricsArrays = [
  "776",
  "is",
  "a",
  "reminder",
  null,
  "to",
  "make",
  "great",
  "achievements",
  null,
  "but",
  "not",
  "repeat",
  "great",
  "mistakes",
];

interface LyricsSectionProps {
  animate?: boolean;
  showLogo?: boolean;
}

const LyricsSection: FC<LyricsSectionProps> = ({ animate, showLogo }) => {
  // We use both ref and state due to react reactivity issues...
  const [passedIndexState, setPassedIndexState] = useState<number | null>(0);
  const passedIndex = useRef<number | null>(0);

  useEffect(() => {
    if (animate && !passedIndex.current) {
      const interval = setInterval(() => {
        const number = passedIndex.current + 1;
        setPassedIndexState(number);
        passedIndex.current = number;
        if (number > lyricsArrays.length) {
          clearInterval(interval);
        }
      }, 200);
    }
  }, [animate, passedIndex]);

  return (
    <LyricsSectionDiv>
      {!showLogo && <div className="spacer" />}
      {showLogo && <LastImg className="svg-logo" />}
      <div>
        {lyricsArrays.map((lyric, index) => (
          <span key={index}>
            {!lyric && <br />}
            {lyric && <span className={passedIndexState > index ? "active" : ""}>{lyric} </span>}
          </span>
        ))}
      </div>
    </LyricsSectionDiv>
  );
};

export default LyricsSection;
