import { FC, useEffect, useState } from "react";

import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";

const DivContainer = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 12;

  display: flex;
  align-items: center;
  flex-direction: column;

  transform: scale(0.7);
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &.animate {
    transform: scale(1);
    opacity: 1;
  }

  .close {
    position: absolute;
    top: 96px;
    right: 81px;
    cursor: pointer;

    circle {
      fill: #f2f2f2;
    }

    padding: 0;
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 999px;
  }

  .images {
    width: 100%;
    height: 100vh;
    aspect-ratio: 1/1;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: nowrap;
    scroll-snap-type: y mandatory;
  }

  .box {
    width: 100%;
    height: 100vh;
    flex-shrink: 0;
    display: flex;
    padding: 30px 200px;
    align-items: center;
    justify-content: space-between;
    scroll-snap-align: center;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      background-color: white;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    .close {
      top: 50px;
      right: 50px;
      svg {
        width: 70px;
        height: 70px;
      }
    }

    .box {
      padding: 50px 145px;
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    .close {
      top: 30px;
      right: 30px;
      svg {
        width: 50px;
        height: 50px;
      }
    }
    .box {
      padding: 80px 0px;
    }
  }
`;

interface GalleryProps {
  index: number;
  images: string[];
  onClose: () => void;
}

const Gallery: FC<GalleryProps> = ({ images, index, onClose }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    setAnimate(true);
  }, []);

  useEffect(() => {
    const images = document.querySelectorAll(".box");
    images[index].scrollIntoView();
  }, [index]);

  return (
    <DivContainer className={animate ? "animate" : ""}>
      <button className="close" onClick={() => onClose()}>
        <CloseIcon />
      </button>

      <div className="images">
        {images.map((img, idx) => (
          <div key={img} className="box">
            <img src={img} alt="" />
          </div>
        ))}
      </div>
    </DivContainer>
  );
};

export default Gallery;
